import * as React from "react";
import Rating from "@mui/material/Rating";
import star from "../../../assets/svgs/Ratings/ratingFullStar.svg";
import emptyStar from "../../../assets/svgs/Ratings/ratingEmptyStar.svg";
import classes from "./index.module.css";
import { Skeleton } from "@mui/material";

interface MRatingProps {
  value: number;
  readOnly?: boolean;
  noGap?: boolean;
  isScrapeProduct?: boolean;
}
const MRating: React.FC<MRatingProps> = ({ isScrapeProduct = false, readOnly = true, value, noGap = false }) => {
  console.log("test pick value", value, isScrapeProduct);
  return (
    <>
      {value !== undefined ? (
        <Rating
          value={value}
          getLabelText={(value: number) => value + " star"}
          precision={0.5}
          icon={<img src={star} alt="star" className={classes.Star} />}
          emptyIcon={<img src={emptyStar} alt="empty-star" className={classes.Star} />}
          readOnly={readOnly}
          className={`${classes.Container} ${noGap && classes.NoGap}`}
        />
      ) : (
        <>{!isScrapeProduct ? <Skeleton width={200} height={50} /> : ""}</>
      )}
    </>
  );
};
export default MRating;
