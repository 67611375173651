import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import classes from "./index.module.css";
import moment from "moment";
import { formatNumberWithCommas } from "../../../../../utils/helpers/numbers";

interface BsrOverAverageProps {
  chartSeries: any;
  chartCategories: any;
  annotations: any;
  totalTickAmount: any;
  chartData: any;
  yTicks: any;
  tooltipData: any;
}
const colors = ["#F94144", "#90BE6D", "#43AA8B", "#2E93fA", "#66DA26", "#546E7A", "#9747FF", "#FF9800"];
const BsrOverAverage: React.FC<BsrOverAverageProps> = ({
  // annotations,
  chartSeries,
  chartCategories,
  totalTickAmount,
  yTicks,
  tooltipData,
}) => {
  console.log("test pick ticks", yTicks);
  const options: ApexOptions = {
    chart: {
      type: "line",
      height: 400,
      stacked: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors,
    // annotations: {
    //   xaxis: annotations,
    // },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 4,
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "15%",
      },
    },
    grid: {
      show: true,
      borderColor: "#D8D8D8",
      strokeDashArray: 2,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 20,
        right: 20,
        bottom: 0,
        left: 0,
      },
    },
    fill: {
      opacity: 0.9,
      gradient: {
        inverseColors: true,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.9,
        opacityTo: 0.15,
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      type: "category",
      categories: chartCategories,
      tickAmount: 10,
      labels: {
        hideOverlappingLabels: true,
        showDuplicates: false,
        minHeight: 50,
        style: {
          colors: "#4F4F4F",
          fontSize: "10px",
          fontWeight: 600,
          fontFamily: "Poppins",
        },
        formatter: (value) => {
          return moment(value).format("DD MMM");
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      tickAmount: totalTickAmount === 1 ? totalTickAmount : totalTickAmount > 5 ? 5 : totalTickAmount - 1,
      forceNiceScale: true,
      labels: {
        show: true,
        align: "right",
        minWidth: 50,
        maxWidth: 200,
        offsetX: -5,
        offsetY: 0,
        rotate: 0,
        style: {
          colors: "#4F4F4F",
          fontSize: "10px",
          fontWeight: 600,
          fontFamily: "Poppins",
        },
        formatter(val: number): string | string[] {
          const result = (1 / val).toString();
          if (result === "Infinity" || result === "infinity") {
            return "";
          } else {
            return formatNumberWithCommas(Math.ceil(Number(result)));
          }
        },
      },
    },
    legend: {
      show: true,
      horizontalAlign: "left",
      fontWeight: 600,
      fontSize: "12px",
      fontFamily: "Poppins",
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true,
      custom: function ({ dataPointIndex }) {
        const ranks = tooltipData.map((item: any, index: number) => {
          return `<div class="${classes.RowContainer}">
                <div class="${classes.RowContainer}">
                    <div class="${classes.Icon}" style="background: ${colors[index]}"></div>
                    <div>${item?.name}</div>
                </div>
                <div>
                       <strong>#${parseInt(item.data[dataPointIndex])}</strong>
                </div>
            </div>`;
        });

        const tooltipContent = `<div class="${classes.Container}">
            <div class="${classes.DateText}"><strong>Date: ${chartCategories[dataPointIndex]}</strong></div>
            ${ranks.join("")}
          </div>`;
        return tooltipContent;
      },
    },
    // colors: ["#ff0000", "#FE464B"],
  };
  return (
    <div>
      <ReactApexChart options={options} series={chartSeries} type="line" height={400} />
      {/*<p className={classes.Title}>Low Sales</p>*/}
    </div>
  );
};
export default BsrOverAverage;
