import React from "react";

const CardOverlay: React.FC<any> = (props) => {
  return (
    <div
      style={{
        paddingTop: "20px",
        paddingBottom: "20px",
        boxShadow: "0px 3px 10px 0px #F0F2F5",
        backgroundColor: "#FFF",
        borderRadius: "10px",
      }}
    >
      {props.children}
    </div>
  );
};

export default CardOverlay;
