import React, { useState } from "react";
import classes from "./index.module.css";
import { countryDomain, CountryDomainObject } from "./config";
import MTypography from "../../Atoms/MTypography";
import dropDown from "../../../assets/svgs/dropdown.svg";
import { useDeviceType } from "../../../hooks/useDeviceType";
import MuiPopover from "../../Atoms/Popover";
import { Divider } from "@mui/material";
import { FetchAsinFormState } from "../FetchAsin/FetchAsinForm";

interface DomainDropDownProps {
  formState: FetchAsinFormState;
  setFormState: React.Dispatch<React.SetStateAction<FetchAsinFormState>>;
}
interface DomainDropDownState {
  showOptions: boolean;
  anchorEl: HTMLDivElement | null;
}
const DomainDropDown: React.FC<DomainDropDownProps> = ({ setFormState, formState }) => {
  const { isDesktop } = useDeviceType();
  const [state, setState] = useState<DomainDropDownState>({
    showOptions: false,
    anchorEl: null,
  });

  const showOptions = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.currentTarget !== null && !formState.fetchProduct) {
      setState({ ...state, showOptions: true, anchorEl: e.currentTarget });
    }
  };

  const hideOptions = () => {
    setState({ ...state, showOptions: false });
  };

  const setDomain = (domain: CountryDomainObject) => {
    setState({ ...state, showOptions: false });
    setFormState({ ...formState, selectedDomain: domain });
  };

  return (
    <div className={``}>
      <MuiPopover
        sx={{ "& .MuiPaper-root": { padding: "4px" } }}
        open={state.showOptions}
        anchorEl={state.anchorEl}
        onClose={hideOptions}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div>
          {countryDomain.map((domain, index) => (
            <div key={domain.name}>
              <div
                className={`d-flex align-items-center ${
                  isDesktop ? classes.DropDownOptions : classes.DropDownOptionsM
                }`}
                onClick={() => setDomain(domain)}
              >
                <img src={domain.flag} alt={domain.name} className={isDesktop ? classes.Flag : classes.FlagM} />
                {isDesktop && (
                  <MTypography variant={"subtitle2"} customClass={classes.Name}>
                    {domain.name}
                  </MTypography>
                )}
              </div>
              {index !== countryDomain.length - 1 && <Divider />}
            </div>
          ))}
        </div>
      </MuiPopover>
      <div
        className={`d-flex align-items-center ${isDesktop ? classes.DropDownContainer : classes.DropDownContainerM}`}
        onClick={(e) => showOptions(e)}
      >
        <img
          src={formState.selectedDomain.flag}
          alt={formState.selectedDomain.name}
          className={isDesktop ? classes.Flag : classes.FlagM}
        />
        {isDesktop && (
          <MTypography variant={"subtitle2"} customClass={classes.Name}>
            {formState.selectedDomain.name}
          </MTypography>
        )}
        <img src={dropDown} alt={"drop-down"} />
      </div>
    </div>
  );
};

export default DomainDropDown;
