import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Formik, Form, FieldArray } from "formik";
import { Box, CardActions, Dialog, DialogContent, DialogTitle, Divider, useMediaQuery } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { ERR_FILL_ALL_EMAILS, ERR_INVALID_EMAILS } from "../../../../utils/constants/messages/errors";
import { validateEmail } from "../../../../utils/helpers/general-helpers";
import { SUCC_INVITATIONS_SENT } from "../../../../utils/constants/messages/success";

import { DbUserSelector } from "../../../../store/user/user.selector";

import { useDeviceType } from "../../../../hooks/useDeviceType";

import { SendInvitesPayload } from "../../../../apis/types/user";
import { UserApis } from "../../../../apis/user";

import { URL_SIGN_UP } from "../../../../routes/routes-path";

import FormTextField from "../../../Molecules/FormTextField";
import MTypography from "../../../Atoms/MTypography";
import MButton from "../../../Atoms/MButton";

import { validationSchema, InviteUser, initialFormValue, initialValue } from "./config";
import classes from "./index.module.css";

interface InviteModalBoxProps {
  open: boolean;
  onClose: () => void;
  getTeamMembersCall: (isReset: boolean, isMobile: boolean) => void;
  companyID?: string;
  handleRefreshPendingList?: any;
}

const InviteModalBox: React.FC<InviteModalBoxProps> = ({
  open,
  onClose,
  getTeamMembersCall,
  companyID = "",
  handleRefreshPendingList,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const currentUser = useSelector(DbUserSelector);
  const [loading, setLoading] = useState<boolean>(false);
  const { isDesktop } = useDeviceType();

  const submitForm = async (values: InviteUser) => {
    const validEmails = values?.invites?.filter((data) => data?.email?.trim() !== "");
    const emails: string[] = validEmails?.map((e: any) => e.email);

    if (emails?.length !== values?.invites?.length) {
      toast.error(ERR_FILL_ALL_EMAILS);
      return;
    }

    if (emails?.some((email: any) => !validateEmail(email))) {
      toast.error(ERR_INVALID_EMAILS);
      return;
    }

    try {
      setLoading(true);
      let payload: SendInvitesPayload = {
        emails: emails,
        userID: currentUser?._id,
        url: `http://${window.location.host}${URL_SIGN_UP}`,
      };

      if (companyID) {
        payload = { ...payload, companyID: companyID };
      }
      const res = await UserApis.sendInvitesToTeam(payload);
      if (!res) {
        handleRefreshPendingList();
        toast.success(SUCC_INVITATIONS_SENT);
        setLoading(false);
        if (isDesktop) {
          getTeamMembersCall(false, false);
        } else {
          getTeamMembersCall(true, true);
        }
        onClose();
      } else {
        toast.error(res);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("An error occurred while sending invites.");
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialFormValue}
        onSubmit={submitForm}
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form autoComplete="off">
            <Dialog
              fullScreen={fullScreen}
              open={open}
              onClose={onClose}
              PaperProps={{
                style: {
                  maxWidth: "fit-content",
                },
              }}
            >
              <DialogTitle className={classes.Title}>
                <div className="flex-grow-1">Invite</div>
              </DialogTitle>
              <DialogContent className={classes.BoxConatiner} style={{ width: "620px" }}>
                <div className="pt-2 mt-2">
                  <MTypography variant={"body2"} customClass={`fw-semibold`}>
                    Invite by Email
                  </MTypography>
                  <MTypography variant={"body2"} customClass={`${classes.InviteesText}`}>
                    Invitees must complete registration to become active team members.
                  </MTypography>
                </div>
                <FieldArray name="invites">
                  {({ push, remove }: any) => (
                    <>
                      {values.invites.map((_invite: any, index: number) => (
                        <div key={index}>
                          <div className={"d-flex gap-2 align-items-center"}>
                            <div className="d-flex w-100 gap-2">
                              <FormTextField fullWidth name={`invites.${index}.email`} label={"Enter Email"} />
                              {/* <FormRolesDropdown minWidth={"35%"} name={`invites.${index}.role`} /> */}
                            </div>
                            <Box component={"div"} sx={{ width: { sx: index === 0 ? "" : "40px" }, pt: 1 }}>
                              {values.invites.length >= 2 && (
                                <div onClick={() => remove(index)} className="cursor-pointer">
                                  <CloseRoundedIcon sx={{ color: "#80828A", fontSize: { xs: "26px", md: "32px" } }} />
                                </div>
                              )}
                            </Box>
                          </div>
                        </div>
                      ))}
                      <MButton onClick={() => push(initialValue)} variant="outlined">
                        Add Another
                      </MButton>
                    </>
                  )}
                </FieldArray>
              </DialogContent>
              <Divider sx={{ mb: 1 }} />
              <CardActions sx={{ alignItems: "flex-end", justifyContent: "flex-end", px: "24px" }}>
                <MButton onClick={() => onClose()} size={"small"} variant={"text"}>
                  Cancel
                </MButton>
                <MButton
                  onClick={() => {
                    submitForm(values);
                  }}
                  isLoading={loading}
                  size={"small"}
                >
                  Invite
                </MButton>
              </CardActions>
            </Dialog>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InviteModalBox;
