import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import classes from "../index.module.css";
import { ProductDetailsEditableKeys } from "../../../../../../../utils/constants/enums";
import { useDispatch, useSelector } from "react-redux";
import { CompleteProductSelector } from "../../../../../../../store/product/productDetails/productDetails.selectors";
import { handleEditingForDetailPageAction } from "../../../../../../../store/product/productDetails/productDetails.actions";
import { formatBulletsPoints } from "../../../../../../../utils/helpers/strings";
import { DbUserSelector } from "../../../../../../../store/user/user.selector";

interface EditableInputProps {
  editableKey: ProductDetailsEditableKeys;
  bulletIndex?: number;
  characterCount: Dispatch<SetStateAction<number>>;
}
const EditableInput: React.FC<EditableInputProps> = ({ editableKey, bulletIndex, characterCount }) => {
  const product = useSelector(CompleteProductSelector);
  const dispatch = useDispatch();
  const currentUser = useSelector(DbUserSelector);
  const [height, setHeight] = useState("auto"); // State to manage the textarea height
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  let value =
    bulletIndex || bulletIndex === 0
      ? formatBulletsPoints(product[`${editableKey}Edited`][bulletIndex], currentUser?.bulletPointsFormat)
      : product[`${editableKey}Edited`];

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      characterCount(value.length);
    }
  }, [value]);

  const handleTextareaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    characterCount(e.target.value?.length);
    dispatch(handleEditingForDetailPageAction(product, editableKey, e.target.value, bulletIndex))
      .then(() => {
        setHeight("auto");
        setHeight(`${e.target.scrollHeight}px`);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };
  return (
    <>
      <textarea
        ref={textareaRef}
        className={`${classes.Body} ${classes.EditableInput} ${
          bulletIndex !== undefined ? classes.PaddingOnePx : classes.PaddingZeroPx
        }`}
        style={{ height }}
        defaultValue={value}
        onChange={handleTextareaChange}
        draggable="false"
      />
    </>
  );
};

export default EditableInput;
