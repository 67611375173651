import { Box, Grid, Skeleton } from "@mui/material";
import React, { useRef } from "react";
import "./keywords.style.scss";

import { GridRowParams } from "@mui/x-data-grid";

import { DataTableRef } from "../../../../interfaces/datatable";

import DataTable from "../../../mui/dataGrid";
import { generateProductGridSkeleton } from "../../../../utils/helpers/skeleton";

const KeywordSkeleton = (): JSX.Element => {
  const tableRef = useRef<DataTableRef>({} as DataTableRef);

  const products: any = generateProductGridSkeleton(20);

  const productsColumns = [
    {
      field: "phrase",
      headerName: "Phrase",
      flex: 2,
      disableColumnMenu: true,
      renderCell: () => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    },
    {
      field: "searchVolume",
      headerName: "Search Volume",
      flex: 1,

      disableColumnMenu: true,
      renderCell: () => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    },
    {
      field: "asin",
      headerName: "Your Rank",
      flex: 1,
      disableColumnMenu: true,
      renderCell: () => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    },
    {
      field: "relevancy",
      headerName: "Relevancy",
      flex: 1,

      disableColumnMenu: true,
      renderCell: () => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    },
    {
      field: "count",
      headerName: "Count",
      flex: 1,
      disableColumnMenu: true,
      renderCell: () => (
        <div className={"w-100"}>
          <Skeleton variant={"text"} />
        </div>
      ),
    },
  ];

  return (
    <Box
      sx={{
        overflow: "auto",
        background: "white",
        m: 5,
        mx: 10,
        borderRadius: "10px",
      }}
    >
      <Grid>
        <DataTable
          ref={tableRef}
          columns={productsColumns}
          rows={products}
          isRowSelectable={(_params: GridRowParams) => false}
          withoutPagination={true}
          checkboxSelection={true}
          keepNonExistentRowsSelected
        ></DataTable>
      </Grid>
    </Box>
  );
};

export default KeywordSkeleton;
