import axios from "../axios";
import {
  RESP_GET_USERS_LIST,
  GetUserApi,
  REQ_UPDATE_USER_PRIVILEGE,
  SaveUserPayload,
  SendInvitesPayload,
  GetTeamMembersPayload,
  GetTeamMembersResponse, CreateInvitedUserPayload, InternalUsersListResponse
} from "./types/user";
import { errorHandler } from "../utils/helpers/apis";

export const UserApis = {
  controller: "/user",
  async getUserList() {
    return axios
      .get<any, RESP_GET_USERS_LIST>(`${this.controller}/list`)
      .then((r: RESP_GET_USERS_LIST) => r.data.data)
      .catch(errorHandler);
  },
  async updateUserPrivilege(obj: REQ_UPDATE_USER_PRIVILEGE) {
    return axios
      .put<any, any>(`${this.controller}/user-privilege`, obj)
      .then((r: GetUserApi) => r.data.data)
      .catch(errorHandler);
  },
  async updateUserSettings(obj: REQ_UPDATE_USER_PRIVILEGE) {
    return axios
      .put<any, any>(`${this.controller}/user-settings`, obj)
      .then((r: GetUserApi) => r.data.data)
      .catch(errorHandler);
  },
  async updateUserSettingsWithError(obj: REQ_UPDATE_USER_PRIVILEGE) {
    return axios
      .put<any, any>(`${this.controller}/user-settings`, obj)
      .then((r: GetUserApi) => r.data.data)
      .catch((e: any) => {
        throw new Error(errorHandler(e));
      });
  },
  async getUser() {
    return axios.get<any, GetUserApi>(`/user`).then((r: GetUserApi) => r.data.data).catch((e: any) => {
      throw new Error(errorHandler(e));
    });
  },
  async getProductOwner(userId: string) {
    return axios.get<any, GetUserApi>(`/user/${userId}`).then((r: GetUserApi) => r.data.data);
  },
  async saveUser(payload: SaveUserPayload) {
    return axios.post<SaveUserPayload, GetUserApi>(`/user/create`, payload).then((r: GetUserApi) => r.data.data);
  },
  async createInternalUser(payload: SaveUserPayload) {
    return axios.post<SaveUserPayload, GetUserApi>(`/user/create-internal-user`, payload).then((r: GetUserApi) => r.data.data);
  },
  async checkCompanyName(companyName: string) {
    return axios.get<any>(`/user/check-company-exist/${companyName}`).then((r: any) => r.data.data);
  },
  async checkIsUserEmailAlreadyExist(email: string) {
    return axios.get<any>(`/user/check-user-exist/${email}`).then((r: any) => r.data.data);
  },
  async sendInvitesToTeam(payload: SendInvitesPayload) {
    return axios
      .post<any, any>(`/user/send-invites`, payload)
      .then((r: any) => r.data.data)
      .catch((error: any) => errorHandler(error)[0]);
  },
  async getTeamMembers(payload: GetTeamMembersPayload) {
    return axios.post<any, GetTeamMembersResponse>(`/user/team-members`, payload).then((r: any) => r.data.data);
  },
  async getCompanyProfile(id: string) {
    return axios.get<any>(`/user/get-company/${id}`).then((r: any) => r.data.data);
  },
  async createInvitedUser(payload: CreateInvitedUserPayload) {
    return axios.post<CreateInvitedUserPayload, GetUserApi>(`/user/create-invited-user`, payload).then((r: GetUserApi) => r.data.data);
  },
  async getInternalUsersList(filters: any) {
    return axios
      .post<any, InternalUsersListResponse>(`${this.controller}/internal-users`, filters)
      .then((r: any) => r.data.data)
      .catch(errorHandler);
  },
};
