import React, { useEffect, useState } from "react";
import { Switch, LinearProgress, Skeleton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { RolesEnum, TabsEnum, UserStatusEnum } from "../../utils/constants/enums";
import { capitalizeEachLetter } from "../../utils/helpers/strings";
import { navigatePage } from "../../utils/helpers/common";
import { SUCC_COMPANY_PROFILE_UPDATED, SUCC_USER_PROFILE_UPDATED } from "../../utils/constants/messages/success";

import { useDeviceType } from "../../hooks/useDeviceType";

import { DbUserSelector } from "../../store/user/user.selector";

import { URL_LANDING_PAGE } from "../../routes/routes-path";

import MTabs from "../../components/Atoms/MTabs";
import RolesDropdown from "../../components/Molecules/RolesDropdown";
import TabsToolbar from "../../components/Molecules/TabsToolbar";
import MTypography from "../../components/Atoms/MTypography";
import MButton from "../../components/Atoms/MButton";
import BasicDetails from "../../components/Organisms/ClientDetail/BasicDetails";
import ClientDetailStats from "../../components/Organisms/ClientDetail/ClientStats";
import InviteModalBox from "../../components/Organisms/TeamManagement/InviteModal/InviteModalBox";

import { UserApis } from "../../apis/user";
import { CompanyApis } from "../../apis/companies";
import { ClientsApis } from "../../apis/clients";

import TeamManagement from "../Settings/TeamManagement/TeamManagement";
import SavedProductsTab from "../SavedProducts";
import { clientsTabsValue } from "../Clients/config";

import {
  getTextBasedOnCompanyRole,
  adminClientTabs,
  adminClientValue,
  individualClientTabs,
  getSelectableRoles,
  showInviteMemberButtonOnClientsView,
} from "./config";

import classes from "./index.module.css";
import { defaultPageSize } from "../../hooks/usePagination";
import { setTeamMembersAction } from "../../store/user/user.actions";
import { setInvitedEmailsOfCompanyDispatch } from "../../store/company/company.actions";

interface ClientDetailProps {
  isCompany: boolean;
}
const ClientDetail: React.FC<ClientDetailProps> = ({ isCompany = false }) => {
  const { isDesktop } = useDeviceType();
  const navigate = useNavigate();
  const params = useParams();
  const currentUser = useSelector(DbUserSelector);
  const dispatch = useDispatch();

  // const userData = useSelector(TeamManagementSelectedMemberSelector);
  // const stats = useSelector(TeamMemberStatsSelector)

  const type = isCompany ? "company" : "individual";

  const [activeTab, setActiveTab] = useState<string>(adminClientValue.basicDetails);
  const [userStatus, setUserStatus] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [individualData, setIndividualData] = useState<any>(null);
  const [companyData, setCompanyData] = useState<any>(null);
  const [roleLoading, setRoleLoading] = useState(false);
  const [refetchUser, setRefetchUser] = useState<number>(0);
  const [selectedRole, setSelectedRole] = useState("");

  const [refetchPendingList, setRefreshPendingList] = useState(1);

  const userData = individualData;

  const id = params?.id;
  const status = isCompany ? companyData?.company?.status : userData?.user?.status;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (id) {
      if (isCompany) {
        setLoading(true);
        CompanyApis.getCompanyCompleteDetail(id)
          .then((res: any) => {
            setLoading(false);
            // setIndividualData(res);
            setCompanyData(res);
            setSelectedRole(res?.company?.role);
            dispatch(setInvitedEmailsOfCompanyDispatch(res?.company?.invitedEmails));
          })
          .catch((e: any) => {
            setLoading(false);
            console.log("error while fetching company details", e);
          });
      } else {
        setLoading(true);
        ClientsApis.getIndividualCompleteDetails(id)
          .then((res: any) => {
            setLoading(false);
            setIndividualData(res);
            // to showing trial instead of user for individuals
            const individualRole = res?.user?.role === RolesEnum.USER ? RolesEnum.TRIAL : res?.user?.role;
            setSelectedRole(individualRole);
          })
          .catch((e: any) => {
            setLoading(false);
            console.log("error while fetching company details", e);
          });
        // setIndividualData(userData);
      }
      // setSelectedRole(userData?.role);
    } else {
      goBack();
    }
  }, [refetchUser, id]);

  const goBack = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.CLIENTS}`, navigate);
  };

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const userStatusInBool = status === UserStatusEnum.Active ? true : false;
    setUserStatus(userStatusInBool);
  }, [status]);

  const updateProfile = async (data: any) => {
    try {
      setRoleLoading(true);
      if (!isCompany) {
        const res = await UserApis.updateUserSettings({
          ...data,
        });
        // @ts-ignore
        if (res) {
          setRoleLoading(false);
          toast.success(SUCC_USER_PROFILE_UPDATED);
          const userStatusInBool = res.status === UserStatusEnum.Active ? true : false;
          setUserStatus(userStatusInBool);
        }
      } else {
        const res = await CompanyApis.updateCompanyProfile({
          ...data,
        });
        // @ts-ignore
        if (res) {
          setRoleLoading(false);
          const userStatusInBool = res.status === UserStatusEnum.Active ? true : false;
          setUserStatus(userStatusInBool);
          toast.success(SUCC_COMPANY_PROFILE_UPDATED);
        }
      }
    } catch (e) {
      console.log("error handleRoleChange", e);
      setRoleLoading(false);
    }
  };

  const handleSwitch = async (e: any) => {
    try {
      const currentStatus = e.target.checked ? UserStatusEnum.Active : UserStatusEnum.Pause;
      setUserStatus(e.target.checked);
      const statusToBeSet = currentStatus === UserStatusEnum.Active ? UserStatusEnum.Active : UserStatusEnum.Pause;
      await updateProfile({ [isCompany ? "companyID" : "userID"]: id, status: statusToBeSet });
      setRefetchUser(refetchUser + 1);
    } catch (e) {
      console.log("error handleSwitch", e);
    }
  };

  const handleRoleChange = async (role: any) => {
    if (!isCompany) {
      const individualRoleForPayload = role === RolesEnum.TRIAL ? RolesEnum.USER : role;
      await updateProfile({ role: individualRoleForPayload, [isCompany ? "companyID" : "userID"]: id });
    } else {
      await updateProfile({ role, [isCompany ? "companyID" : "userID"]: id });
      // setSelectedRole(role);
    }
  };

  const [openInviteBox, setOpenInviteBox] = useState<boolean>(false);
  const handleInvite = () => {
    setOpenInviteBox(true);
  };

  const getTeamMembers = (isReset: boolean, isMobile: boolean) => {
    let filters: any = {
      offset: 0,
      limit: defaultPageSize,
      sortBy: "",
      sortOrder: 0,
      searchQuery: "",
      companyID: companyData?.company?._id,
    };

    setLoading(true);
    UserApis.getTeamMembers(filters)
      .then((res: any) => {
        dispatch(setTeamMembersAction(res, isReset, isMobile));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleRefreshPendingList = () => {
    setRefreshPendingList(refetchPendingList + 1);
  };

  return (
    <div>
      {isDesktop ? (
        !loading ? (
          <TabsToolbar
            title={
              capitalizeEachLetter(isCompany ? companyData?.company?.name : userData?.user?.name || "") || "Individual"
            }
            children={
              <div className="d-flex align-items-center gap-4">
                <div className={"cursor-pointer"} onClick={goBack}>
                  <MTypography variant={"subtitle2"}>{"< Back"}</MTypography>
                </div>
                {isCompany && showInviteMemberButtonOnClientsView(currentUser?.email) && (
                  <MButton onClick={handleInvite}>Invite Member</MButton>
                )}
              </div>
            }
          />
        ) : (
          <div style={{ padding: "24px 32px 8px" }}>
            <Skeleton variant="rectangular" width={"280px"} height={"30px"} />
          </div>
        )
      ) : (
        <div className={"d-flex flex-column p-2 m-2"}>
          <div className={"my-2 mx-2 d-flex justify-content-between align-items-center"}>
            <ArrowBackIcon onClick={goBack} />
            {isCompany && showInviteMemberButtonOnClientsView(currentUser?.email) && (
              <MButton onClick={handleInvite}>Invite Member</MButton>
            )}
          </div>
          <div className={"my-2 mx-2"}>
            {loading ? (
              <div style={{ padding: "" }}>
                <Skeleton variant="rectangular" width={"240px"} height={"24px"} />
              </div>
            ) : (
              <MTypography variant={"h5"}>
                {capitalizeEachLetter(isCompany ? companyData?.company?.name : userData?.user?.name || "")}
              </MTypography>
            )}
          </div>
        </div>
      )}

      <div
        style={{ paddingRight: isDesktop ? "32px" : "18px" }}
        className={`d-flex ${isDesktop ? "flex-row align-items-center" : "flex-column"} justify-content-between`}
      >
        <div className={`${isDesktop ? classes.InputContainer : classes.InputContainerM} flex-grow-1`}>
          <div style={{ minHeight: "10px" }}> {roleLoading && <LinearProgress />}</div>
          <RolesDropdown
            name={"role"}
            label={isCompany ? "Company's Role" : "Individual Role"}
            value={selectedRole}
            handleChange={handleRoleChange}
            selectableRoles={getSelectableRoles(isCompany ? clientsTabsValue.companies : clientsTabsValue.individuals)}
          />
          <p className={classes.TextInputSubtitle}>
            {getTextBasedOnCompanyRole(isCompany ? companyData?.company?.role : userData?.user?.role, isCompany)}
          </p>
        </div>
        {userData?.role !== RolesEnum.COMPANY_ADMIN && (
          <div className={!isDesktop ? classes.SiwtchContainer : ""}>
            <span className={classes.ActiveInactive}>{"Active / Inactive "}</span>{" "}
            <Switch size="small" checked={userStatus} onChange={(e: any) => handleSwitch(e)} />
          </div>
        )}
      </div>
      <div>
        <ClientDetailStats
          isLoading={loading}
          userData={isCompany ? companyData : individualData}
          isCompany={isCompany}
        />
        <div className={`${isDesktop ? classes.Container : classes.ContainerM} mt-3`}>
          <div className={"d-flex mb-3"}>
            <MTabs
              handleChange={handleActiveTab}
              value={activeTab}
              tabs={isCompany ? adminClientTabs : individualClientTabs}
              isCamelCase={false}
            />
          </div>

          {activeTab === adminClientValue.basicDetails && (
            <div className={"w-100"}>
              <BasicDetails
                type={type}
                userData={isCompany ? companyData : userData?.user}
                isLoading={loading}
                isCompany={isCompany}
              />
            </div>
          )}

          {activeTab === adminClientValue.members && (
            <div className={"w-100 "}>
              <div className={"mt-4"} />
              <TeamManagement
                refetchPendingList={refetchPendingList}
                type={type}
                isClientsScreen
                isGridOnly
                companyID={isCompany ? companyData?.company?._id : ""}
              />
            </div>
          )}
          {activeTab === adminClientValue.products && (
            <div className={"w-100 "}>
              <div className={"mt-4"} />
              <SavedProductsTab isTeamManagementUser teamUserId={id} isCompany={isCompany} />
            </div>
          )}
        </div>
        {openInviteBox && (
          <InviteModalBox
            handleRefreshPendingList={handleRefreshPendingList}
            open={openInviteBox}
            onClose={() => setOpenInviteBox(false)}
            getTeamMembersCall={getTeamMembers}
            companyID={companyData?.company?._id}
          />
        )}
      </div>
    </div>
  );
};

export default ClientDetail;
