import {
  firebaseCreateUserWithEmailAndPassword,
  firebaseSetBrowserLocalPersistence,
  firebaseSignInWithEmailAndPassword,
  firebaseSignOut,
  firebaseUpdateUserName,
  firebaseUserToken,
} from "../firebase/auth";
import { KEY_USER_ACCESS_TOKEN } from "../utils/constants/store";

import { navigatePage } from "../utils/helpers/common";
import { URL_SIGN_IN } from "../routes/routes-path";

import { NavigateFunction } from "react-router-dom";
import { errorHandler, handleAuthError } from "../utils/helpers/apis";
import { savedProductsDispatch, setActiveBrandTabDispatch, setProductSearchFilterDispatch } from "../store/product/product.actions";
import React from "react";
import { UserApis } from "./user";
import { reSetOpportunityReportFilter, setOpportunitySearchFilterDispatch } from "../store/opportunity-report/opportunityReport.actions";


export const establishUserLoginSession = async (
  email?: string,
  password?: string,
) => {
  let token = null;
  let getFirebaseUser;
  try {
    if (email?.length && password?.length) {
      getFirebaseUser = await firebaseSignInWithEmailAndPassword(email, password);
      token = await getFirebaseUser.user.getIdToken();
    } else {
      const getToken = await firebaseUserToken();
      token = getToken?.token || null;
    }
    if (token?.length) localStorage.setItem(KEY_USER_ACCESS_TOKEN, token);
    await firebaseSetBrowserLocalPersistence();
    // return true;
  } catch (e: any) {
    throw new Error(handleAuthError(e));
  }
  try {
    if (token) return await UserApis.getUser();
    return null;
  } catch (e: any) {
    if (e?.response?.data?.statusCode === 400 && "data" in e?.response) {
      throw new Error(e?.response?.data?.message)
    } if (e?.response?.data?.statusCode === 403) {
      throw new Error(e?.response?.data?.message)
    } else {
      throw new Error(errorHandler(e));
    }
  }
};

export const logoutUser = async (navigate: NavigateFunction, dispatch: React.Dispatch<any>) => {
  try {
    await firebaseSignOut();
    dispatch(savedProductsDispatch([]));
    dispatch(setActiveBrandTabDispatch("ASIN"));
    dispatch(reSetOpportunityReportFilter());
    dispatch(setOpportunitySearchFilterDispatch(""));
    dispatch(setProductSearchFilterDispatch(""));
    sessionStorage.clear();
    localStorage.clear();
    navigatePage(URL_SIGN_IN, navigate, { replace: true });
  } catch (e) {
    handleAuthError(e);
  }
};

export const createNewUserOnFirebase = async ({
  email,
  password,
  username,
}: {
  username: string;
  email: string;
  password: string;
}) => {
  let createFirebaseUser = null;
  try {
    createFirebaseUser = await firebaseCreateUserWithEmailAndPassword(email, password);
    await firebaseUpdateUserName(username);
  } catch (e: any) {
    throw handleAuthError(e);
  } finally {
    if (createFirebaseUser) await firebaseSignOut();
  }
};
