import React, { useContext, useState } from "react";
import MTypography from "../../../Atoms/MTypography";
import MButton from "../../../Atoms/MButton";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import { toast } from "react-toastify";
import {
  ASIN_LIMIT,
  ERR_ASIN_EMPTY,
  ERR_ASIN_INVALID_LENGTH,
  ERR_ASIN_INVALID_START,
  ERR_INVALID_ASIN_LINK,
  UnlockGreaterPossibilities,
} from "../../../../utils/constants/messages/errors";
import classes from "./index.module.css";
import { fetchAmazonASIN } from "../../../../utils/helpers/regex";
import DomainDropDown from "../../DomainDropDown";
import { countryDomain } from "../../DomainDropDown/config";
import nextArrow from "../../../../assets/svgs/nextArrow.svg";
import { useDispatch, useSelector } from "react-redux";
import ToastError from "../../../Atoms/ToastError";

import { errorHandler } from "../../../../utils/helpers/apis";

import {
  fetchGoGoProductAction,
  rainForestProductAction,
  resetScrapeProduct,
  updateProductDomainAction,
} from "../../../../store/product/productScrape/productScrape.actions";
import { CountryDomainEnum, ProductFetchType, RolesEnum, TabsEnum } from "../../../../utils/constants/enums";
import ActionBox from "../../../Molecules/ActionBox";
import { ScrapeProductSelector } from "../../../../store/product/productScrape/productScrape.selectors";
import WhatIsGoGoToolTip from "../../../Molecules/WhatIsGoGoToolTip";
import { DbUserSelector, UserRoleSelector } from "../../../../store/user/user.selector";
import { userRole } from "../../../../utils/helpers/priviligesChecks";
import ChipInput from "../../../Molecules/ChipInput/ChipInput";
import { ProductApis } from "../../../../apis/product";
import { navigatePage } from "../../../../utils/helpers/common";
import { URL_LANDING_PAGE } from "../../../../routes/routes-path";
import { useNavigate } from "react-router-dom";
import BulkImportBox from "../BulkImportBox/BulkImportBox";
import { Box } from "@mui/material";
import { bulkAsinsLimitUsingFile } from "../BulkImportBox/config";
import {
  checkIsCompanyReachedHelium10Limit,
  checkIsUserReachedHisHelium10Limit,
} from "../../../../pages/SavedProducts/config";
import PricingContext from "../../../../context/PricingContext";
import { CompanySelector } from "../../../../store/company/company.selector";
import KeywordsPageDialoge from "../../../mui/dialogebox/keywordsPageDialoge";
import { generatingProcessTimeTenToFifteen } from "../../../../utils/constants/common";

interface FetchAsinFormProps {
  productFetch?: boolean;
  setProductFetch: React.Dispatch<React.SetStateAction<boolean>>;
  isVisible: boolean;
}
export interface FetchAsinFormState {
  asinId: string;
  loading: boolean;
  productResp: any;
  centerBl: boolean;
  selectedDomain: { name: CountryDomainEnum; flag: string };
  fetchProduct: boolean;
}
const FetchAsinForm = ({ productFetch, setProductFetch, isVisible }: FetchAsinFormProps) => {
  const navigate = useNavigate();
  const scrapeProduct = useSelector(ScrapeProductSelector);
  const currentUser = useSelector(DbUserSelector);
  const role = useSelector(UserRoleSelector);
  const company = useSelector(CompanySelector);
  const dispatch = useDispatch();
  const [state, setState] = useState<FetchAsinFormState>({
    asinId: "",
    loading: false,
    productResp: {} as any,
    centerBl: true,
    selectedDomain: countryDomain[0],
    fetchProduct: Boolean(productFetch),
  });

  const [chips, setChips] = useState<any>([]);

  const [loadingGoGO, setLoadingGoGo] = useState<boolean>(false);

  const [openConfirmBox, setOpenConfirmBox] = useState<boolean>(false);
  const [selectedAsinsArray, setSelectedAsinsArray] = useState<string[]>([]);
  const [showBulkImportBox, setShowBulkImportBox] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleChips = (value: any) => setChips(value);

  const handleMultipleGogo = (listType: string): any => {
    let isError = false;
    // asinsList added to handle if user comes from Bulk import Modal
    // for chips and selectedAsinsArray
    let asinsList = listType === "chips" ? chips : selectedAsinsArray;
    let asinsListLimit = listType === "chips" ? 10 : bulkAsinsLimitUsingFile;

    asinsList.forEach((asin: string) => {
      const fetchAsin = fetchAmazonASIN(asin.trim());
      if ((!fetchAsin && fetchAsin !== asin) || asin.length !== 10) {
        isError = true;
      }
    });

    if (isError) {
      toast.error(ERR_INVALID_ASIN_LINK);
      return false;
    } else if (asinsList.length > asinsListLimit) {
      toast.error(ASIN_LIMIT);
      return false;
    }
    const domain: string = state.selectedDomain.name;
    setLoadingGoGo(true);
    ProductApis.generateMultipleGogo({ productASINS: asinsList, domain, userID: currentUser?._id })
      .then(() => {
        setLoadingGoGo(false);
        setIsOpen(true)
      })
      .catch((err: any) => {
        console.log("handleMultipleGogo", err);
        setLoadingGoGo(false);
      });
  };

  const { onOpen } = useContext(PricingContext); 

  const showUpgradeMessage = ()=>{
    if(currentUser?.companyID){
      if(currentUser?.role === RolesEnum.COMPANY_ADMIN){
        onOpen()
      }else{
        toast.warn(<ToastError error={UnlockGreaterPossibilities} showPricingPlan={false} />);
      }
    }else{
      onOpen()
    }
  }

  const handleFetchProduct = async (type: ProductFetchType) => {
    // const isLimitReached = await checkIsUserReachedHisHelium10Limit(currentUser?._id);
    const isLimitReached = currentUser?.companyID
      ? await checkIsCompanyReachedHelium10Limit(currentUser?.companyID)
      : await checkIsUserReachedHisHelium10Limit(currentUser?._id);

      
      if (isLimitReached) {
        toast.warn(<ToastError error={UnlockGreaterPossibilities} />);
        showUpgradeMessage();
      } else {
      if (!productFetch) {
        const fetchAsin = fetchAmazonASIN(state.asinId as string);
        const domain: string = state.selectedDomain.name;
        const isProductGogo = type === ProductFetchType.GOGO;

        if (chips.length > 0) {
          handleMultipleGogo("chips");
        } else if (fetchAsin && fetchAsin.length === 10 && fetchAsin.substring(0, 2) === "B0") {
          if (isProductGogo) {
            setLoadingGoGo(true);
            setIsOpen(true)
          }
          setState({ ...state, loading: true });
          setProductFetch(true);
          const action =
            type === ProductFetchType.STANDARD
              ? rainForestProductAction({ domain, asin: fetchAsin, isGoGoAutomato: false })
              : fetchGoGoProductAction({ domain, asin: fetchAsin, isGoGoAutomato: true });
          dispatch(action)
            .then(() => {
              setState({ ...state, fetchProduct: true, loading: false });
              if (isProductGogo) {
                setLoadingGoGo(false);
              }
            })
            .catch((e: any) => {
              // type === ProductFetchType.STANDARD ? toast.error(<ToastError error={e} />) : toast.error(errorHandler(e));
              type === ProductFetchType.STANDARD
                ? toast.warning(<ToastError error={e} />)
                : toast.warning(
                    // errorHandler(e)
                    <ToastError error={e} />
                  );
              setProductFetch(false);
              setState({ ...state, fetchProduct: false, loading: false });
              setLoadingGoGo(false);
            });
        } else {
          if (!state.asinId) toast.error(ERR_ASIN_EMPTY);
          else if (!fetchAsin) toast.error(ERR_INVALID_ASIN_LINK);
          else if (state.asinId.length !== 10) toast.error(ERR_ASIN_INVALID_LENGTH);
          else toast.error(ERR_ASIN_INVALID_START);
        }
      }
    }
  };

  const resetForm = () => {
    setProductFetch(false);
    resetScrapeProduct(dispatch);
    setState({ ...state, asinId: "", selectedDomain: countryDomain[0], fetchProduct: false });
  };

  const { isDesktop, isMobile, isIpad } = useDeviceType();

  const handleAsinClick = () => window.open("https://automatoai.com/what-is-an-asin", "_blank")?.focus();
  const handleUpdateDomain = () => {
    if (scrapeProduct?.updatingDomainLoading) {
      toast.warning("Please wait a moment while we process your request. Thank you for your patience.");
    } else {
      try {
        dispatch(updateProductDomainAction(scrapeProduct, state.selectedDomain.name, true)).catch((e: any) => {
          throw new Error(e);
        });
      } catch (e) {
        toast.error(errorHandler(e));
      }
    }
  };
  const handleNoUpdateDomain = () => {
    try {
      dispatch(updateProductDomainAction(scrapeProduct, scrapeProduct?.domain as CountryDomainEnum, false))
        .then(() => {
          setState({
            ...state,
            selectedDomain: countryDomain.find((d) => d.name === scrapeProduct?.domain) || state.selectedDomain,
          });
        })
        .catch((e: any) => {
          throw new Error(e);
        });
    } catch (e) {
      toast.error(errorHandler(e));
    }
  };

  const isControlDisabled = currentUser?.companyID
    ? company?.contentGenerationLimit === company?.countContentGeneration
    : currentUser?.contentGenerationLimit === currentUser?.countContentGeneration && userRole.isUser(role);

  const isControlDisabledForBulkImport = currentUser?.companyID 
    ? company?.contentGenerationLimit === company?.countContentGeneration 
    : currentUser?.contentGenerationLimit === currentUser?.countContentGeneration &&
    userRole.isPaidOrPaidEditorOrUser(role);

  // page 2 fetchAsinForm // model that shows product exist w d domain

  const handleClose = () => {
    setShowBulkImportBox(false);
  };

  const handleContinueClick = () => {
    setOpenConfirmBox(true);
    setShowBulkImportBox(false);
  };

  const handleDone = (): any => {
    setOpenConfirmBox(false);
    handleMultipleGogo("selectedAsinsArray");
  };

  const handleBulkModalCancel = () => {
    setOpenConfirmBox(false);
    setSelectedAsinsArray([]);
  };

  const closeDialogBox = () => {
    setIsOpen(false);
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SAVED}`, navigate, { replace: true });
  };

  return (
    <div
      className={isMobile || isIpad ? "my-2 d-flex flex-column" : `d-flex flex-column w-100 ${classes.Container}`}
      style={{ marginTop: (isIpad || isDesktop) && !productFetch ? "10%" : "5%" }}
    >
      <KeywordsPageDialoge open={isOpen} onClose={closeDialogBox} title={generatingProcessTimeTenToFifteen} />
      <div className={"my-3 text-center d-flex align-items-center justify-content-center "}>
        <div className={`${classes.WhatIsAsinContainer}`} onClick={handleAsinClick}>
          <div className={classes.HelpContainer}>
            <MTypography variant={"subtitle2"} customClass={classes.HelpText}>
              {"Help"}
            </MTypography>
          </div>
          <MTypography variant={"subtitle1"} customClass={classes.WhatIsAsinText}>
            What is an ASIN?
          </MTypography>
          <img src={nextArrow} alt="arrow" className={classes.NextArrow} />
        </div>
      </div>
      {isVisible && (
        <>
          <div className={"my-2 text-center"}>
            <MTypography variant={"h4"}>{"Enter amazon product ASIN"}</MTypography>
          </div>

          <div className={"mb-2 text-center"}>
            <MTypography variant={"h6"} customClass={classes.AsinFormDescription} color={"rgba(126, 126, 126, 1)"}>
              You can enter up to <span className={classes.fwBold600}>10</span> ASINs{" "}
              <span className={classes.fwBold600}>space</span> or{" "}
              <span className={classes.fwBold600}>comma-separated.</span>
            </MTypography>
          </div>
          <div className={"mb-2 text-center"}>
            <MTypography variant={"h6"} customClass={classes.AsinFormDescription} color={"rgba(126, 126, 126, 1)"}>
              After typing in each ASIN, <span className={classes.fwBold600}>press the </span>
              <span className={classes.fwBold600}>enter key </span>to add.
            </MTypography>
          </div>

          <Box component={"div"} className={"d-flex my-3 text-center align-items-center justify-content-center mw-100"}>
            <div className={""}>
              <DomainDropDown setFormState={setState} formState={state} />
            </div>
            <Box component={"div"} sx={{ width: { xs: "80%", sm: "100%" } }}>
              <ChipInput
                chips={chips}
                handleSetChips={handleChips}
                handleChangeValue={(value: any) => setState({ ...state, asinId: value })}
                readonly={productFetch}
                resetForm={resetForm}
                placeholder={isDesktop ? "Enter up to 10 ASINs / Paste product link" : "Enter up to 10 ASINs"}
              />
            </Box>
          </Box>
          <div className={"d-flex flex-row mt-2 justify-content-end"}>
            {isDesktop ? (
              <div className={"w-100"}>
                <div className={"d-flex w-100 justify-content-between gap-3"}>
                  {userRole.isUser(role) ? (
                    <p className={classes.LimitText}>
                    {currentUser?.companyID ? 
                      <>{company?.countContentGeneration} of {company?.contentGenerationLimit} ASINs added</>
                    :
                      <>{currentUser?.countContentGeneration} of {currentUser?.contentGenerationLimit} ASINs added</>
                    }
                    </p>
                  ) : (
                    <div></div>
                  )}
                  <div className={"d-flex gap-2"}>
                    <MButton
                      size={"medium"}
                      disabled={productFetch || isControlDisabledForBulkImport || chips.length > 0}
                      variant={"outlined"}
                      onClick={() => setShowBulkImportBox(true)}
                    >
                      {"Bulk Import"}
                    </MButton>
                    <MButton
                      size={"medium"}
                      disabled={productFetch || isControlDisabled || chips.length > 0}
                      variant={"outlined"}
                      onClick={() => handleFetchProduct(ProductFetchType.STANDARD)}
                      rootClass={classes.RunManual}
                    >
                      {productFetch && !loadingGoGO && state.loading ? "Loading" : "Run Manual"}
                    </MButton>
                    <MButton
                      size={"medium"}
                      disabled={productFetch || loadingGoGO || isControlDisabled}
                      onClick={() => handleFetchProduct(ProductFetchType.GOGO)}
                      rootClass={classes.GoGoButton}
                    >
                      <div className={"d-flex align-items-center gap-1"}>
                        {loadingGoGO ? "Loading" : "Go Go AutoMato"}
                      </div>
                    </MButton>
                  </div>
                </div>
                <WhatIsGoGoToolTip />
              </div>
            ) : (
              <div className={"d-flex flex-column gap-3 w-100"}>
                <div className={"d-flex flex-column w-100"}>
                  <MButton
                    size={"medium"}
                    variant={"contained"}
                    disabled={productFetch || loadingGoGO || isControlDisabled}
                    onClick={() => handleFetchProduct(ProductFetchType.GOGO)}
                  >
                    <div className={"d-flex align-items-center gap-1"}>
                      {loadingGoGO ? "Loading" : "Go Go AutoMato"}
                    </div>
                  </MButton>
                  <WhatIsGoGoToolTip mobile />
                </div>
                <MButton
                  onClick={() => handleFetchProduct(ProductFetchType.STANDARD)}
                  width={"large"}
                  size={"medium"}
                  variant={"outlined"}
                  rootClass={"comp-button-mb"}
                  disabled={productFetch || isControlDisabled || chips.length > 0}
                >
                  {productFetch && !loadingGoGO && state.loading ? "Loading" : "Run Manual"}
                </MButton>
                <MButton
                  width={"large"}
                  size={"medium"}
                  rootClass={"comp-button-mb"}
                  disabled={productFetch || isControlDisabled}
                  variant={"outlined"}
                  onClick={() => setShowBulkImportBox(true)}
                >
                  {"Bulk Import"}
                </MButton>
              </div>
            )}
          </div>

          <ActionBox
            backText={"Cancel"}
            actionText={"Confirm"}
            handleAction={handleUpdateDomain}
            title={"Product already added"}
            message={`You've already added this product from the ${
              scrapeProduct?.domain || "another"
            } marketplace. Do you want to add this product to the ${state.selectedDomain.name} marketplace?`}
            open={Boolean(scrapeProduct?.confirmDomainUpdateBox)}
            handleBack={handleNoUpdateDomain}
          />
          {/* showBulkImportBox, setShowBulkImportBox */}
          <BulkImportBox
            open={showBulkImportBox}
            onClose={handleClose}
            handleContinueClick={handleContinueClick}
            setSelectedAsinsArray={setSelectedAsinsArray}
            selectedAsinsArray={selectedAsinsArray}
          />

          <ActionBox
            handleAction={handleDone}
            handleBack={handleBulkModalCancel}
            open={openConfirmBox}
            backText={"Cancel"}
            actionText={"Done"}
            title={"Import Summary"}
          >
            <MTypography variant="subtitle2" customClass={classes.ActionBoxText}>
              You have successfully imported the file.
            </MTypography>
            <MTypography variant="subtitle2" customClass={classes.ActionBoxText}>
              Here are the summary results.
            </MTypography>
            <Box sx={{ display: "flex", justifyContent: "space-between", mt: "15px", mb: "16px" }}>
              <MTypography variant="subtitle1">Total Imported</MTypography>
              <MTypography variant="subtitle2" customClass={classes.ActionBoxText}>
                {selectedAsinsArray?.length}
              </MTypography>
            </Box>
          </ActionBox>
        </>
      )}
    </div>
  );
};
export default FetchAsinForm;
