export enum ContentStatusEnum {
  GENERATING = "generating",
  DRAFT = "pending",
  APPROVED = "approved",
  EDITED = "pending_approval",
  FETCH_PRODUCT = "fetch_product",
  ERROR = "error",
  WAITING = "waiting",
  NOT_FOUND = "not_found",
  FETCHING = "fetching",
  RESTRICTED = "restricted",
  PUBLISHED = "published"
}
export enum ProductLiveStatusEnum {
  LIVE = "live",
  INACTIVE = "inactive",
  PARTIALLY_LIVE = "partially_live",
  CHECKING = "checking",
  NEVER = "never",
  Waiting = "waiting",
  NOT_FOUND = "not_found",
  FETCHING = "fetching",
}
export enum RolesEnum {
  ADMIN = "admin",
  EDITOR = "editor",
  INTERNAL = "internal",
  OWNER = "owner",
  PAID = "paid", // paid user
  PAID_EDITOR = "paid_editor",
  USER = "user", // trial user // and team member
  COMPANY_ADMIN = "company_admin",
  TRIAL = "trial", // for trial company
}

export enum ProductFetchType {
  STANDARD = "Standard",
  MANUAL = "Manual",
  GOGO = "Go Go",
}

export enum PagesEnum {
  ENTER_ASIN = "enterAsin",
  COMPETITORS = "competitors",
  KEYWORDS = "keywords",
  PRIORITISE = "arrange-keywords",
}

export enum TabsEnum {
  SAVED = "saved-products",
  REVIEW = "review-products",
  BLACKLIST = "keyword-blocklist",
  ACCOUNT_MANAGEMENT = "account-management",
  MORE = "more",
  SETTINGS = "settings",
  PRODUCTS = "Products",
  OPPORTUNITY_REPORT = "opportunity-report",
  SAVED_ASIN = "saved-products-asin",
  CLIENTS = "clients",
}

export enum SettingsEnum {
  BRAND = "brand",
  GoGoAutomato = "go-go-automato-settings",
  API_ACCESS_KEYS = "api-access-keys", // using constants only for ui instead of this enum
  AUTOMATO_API_ACCESS = "automato-api-access", // using constants only for ui instead of this enum
  // BULLET_POINT_FORMAT = "bullet-point-format",
  CONTENT_FORMAT_SETTINGS = "content-format-settings",
  BRAND_ALIAS_MANAGEMENT = "brand-management",
  COMPANY_PROFILE = "company-profile",
  USER_PROFILE = "user-profile",
  TEAM_MANAGEMENT = "team-management",
  USERS = "users",
  ADD_INTERNAL_USER = "add-internal-user",
  BRAND_VOICE = "brand-voice",
  ADD_BRAND_VOICE = "add-brand-voice",
}

export enum RegistersEnum {
  INDIVIDUAL = "individual",
  COMPANY = "company",
}

export enum ContentScoreEnum {
  OLD_DATA = "oldData",
  NEW_DATA = "newData",
}

export enum CatalogEnum {
  DETAIL_PAGE = "details",
  REVIEW_PAGE = "review",
}

export enum ParamsEnum {
  ASIN = "asin",
  TAB = "tab",
  CATALOG = "catalog",
  USER_ID = "userID",
  DOMAIN = "domain",
  BRAND = "brand",
}

export enum UserStatusEnum {
  Active = "active",
  Pause = "pause",
  Inactive = "inactive",
  Pending = "pending",
}

export enum BulletFormatEnum {
  UPPERCASE_COLON = "HEADLINE EXAMPLE: The bullet point content",
  PARENTHESIS_COLON = "【Headline Example】The bullet point content",
  DEFAULT_COLON = "Headline example: The bullet point content",
  UPPERCASE_HYPHEN = "HEADLINE EXAMPLE - The bullet point content",
  DEFAULT_HYPHEN = "Headline Example - The bullet point content",
}

export enum BulletsLengthFormatEnum {
  LONG = "long",
  SHORT = "short",
}
export enum CountryDomainEnum {
  AMAZON_USA = "amazon.com",
  AMAZON_UK = "amazon.co.uk",
  AMAZON_CANADA = "amazon.ca",
}

export enum ProductDetailsEditableKeys {
  TITLE = "generatedTitle",
  BULLET_POINTS_BY_CHAT = "bulletPointsByChat",
  DESCRIPTION = "generatedDescription",
  SEARCH_TERMS = "searchTerm",
  BULLET_POINTS = "bulletPoints",
}

export enum TagsActionEnums {
  ADD = "add",
  REMOVE = "remove",
}

export enum SwipeableTabTitlesEnums {
  TITLE = "Item Name (Title)",
  BULLET_POINTS = "Bullet Points",
  DESCRIPTION = "Product Description",
}

export enum BlacklistWordsEnum {
  BROAD = "broad",
  EXACT = "exact",
}
