import React from "react";
import Checkbox from "@mui/material/Checkbox";
import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import { CheckboxProps } from "@mui/material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import classes from "./index.module.css";
import unSelectIcon from "../../../assets/svgs/Checkbox/Square/rowUnSelectIcon.svg";
import interMediateIcon from "../../../assets/svgs/Checkbox/Square/rowSelectIntermidate.svg";
import selectIcon from "../../../assets/svgs/Checkbox/Square/rowSelectIcon.svg";
type MCheckboxProps = CheckboxProps & {
  variant?: "circle" | "square";
};
const MCheckbox = (props: MCheckboxProps) => {
  const variant = props?.variant || "circle";
  return (
    <Checkbox
      icon={
        variant === "circle" ? (
          <CircleUnchecked className={classes.Checkbox} />
        ) : (
          <img src={unSelectIcon} alt={"row-select-icon"} />
        )
      }
      indeterminateIcon={
        variant === "circle" ? (
          <IndeterminateCheckBoxIcon className={classes.Checkbox} />
        ) : (
          <img src={interMediateIcon} alt={"row-select-icon"} />
        )
      }
      checkedIcon={
        variant === "circle" ? (
          <CircleCheckedFilled className={classes.Checkbox} />
        ) : (
          <img src={selectIcon} alt={"row-select-icon"} />
        )
      }
      {...props}
    />
  );
};

export default MCheckbox;
