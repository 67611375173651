import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import classes from "./index.module.css";
import Switch from "@mui/material/Switch";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { GridCellParams, GridColDef, GridSelectionModel, MuiEvent } from "@mui/x-data-grid";
import ProductTable from "../../../SavedProducts/ProductDataGrid/ProductTable";
import { userRole } from "../../../../../utils/helpers/priviligesChecks";
import { useSelector } from "react-redux";
import { DbUserSelector, UserRoleSelector } from "../../../../../store/user/user.selector";
import GridSkeleton from "../../../SavedProducts/GridSkeleton/GridSkeleton";
import { toDateAndTimeObject } from "../../../../../utils/helpers/date";
import { capitalizeEachLetter } from "../../../../../utils/helpers/strings";
import { BrandListItem } from "../BrandAliasManagement";

export interface BrandAliasTableProps {
  brandsList: BrandListItem[];
  editBrand: (brand: any) => void;
  getAllBrands: any;
  totalBrands: number;
  pageSize: number;
  setPageNumber: any;
  pageNumber: number;
  setPageSize: any;
  sortOrder: number;
  offset: number;
  sortBy: any;
  setSortModel: any;
  handleHideToggle: any;
  loading: boolean;
}

const BrandAliasTable: React.FC<BrandAliasTableProps> = (props) => {
  const {
    brandsList,
    editBrand,
    totalBrands,
    pageSize,
    setPageNumber,
    pageNumber,
    setPageSize,
    sortOrder,
    sortBy,
    setSortModel,
    handleHideToggle,
    loading,
  } = props;
  const role = useSelector(UserRoleSelector);
  const currentUser = useSelector(DbUserSelector);

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  console.log(selectedRows);

  const handleCellClick = async (gridCellParams: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    event.defaultMuiPrevented = true;
    if (gridCellParams.field === "name") {
      editBrand(gridCellParams.row);
    }
  };

  const onSelectedRows = (e: GridSelectionModel) => {
    const selectedIDs = new Set(e);
    setSelectedRows(brandsList.filter((row: any) => selectedIDs.has(row.id)));
    setSelectionModel(e);
  };

  const productsColumns: GridColDef[] = [];
  productsColumns.push({
    field: "name",
    headerName: "Brand name",
    width: 250,
    sortingOrder: ["desc", "asc"],
    renderCell: (params: GridCellParams<any>) => (
      <div className={"d-flex align-items-center"}>
        <div className={`${classes.CellLinesLimitContainer} ${classes.BrandName} HoverEffectForLinks`}>
          {capitalizeEachLetter(params.row.name)}
        </div>
      </div>
    ),
  });
  productsColumns.push({
    field: "alias",
    headerName: "Brand Alias",
    width: 250,
    sortingOrder: ["desc", "asc"],
    renderCell: (params: GridCellParams<any>) => (
      <div className={"d-flex align-items-center"}>
        <div className={`${classes.CellLinesLimitContainer}`}>{params.row.alias}</div>
      </div>
    ),
  });
  productsColumns.push({
    field: "voice",
    headerName: "Brand Voice",
    width: 250,
    sortingOrder: ["desc", "asc"],
    renderCell: (params: GridCellParams<any>) => (
      <div className={"d-flex align-items-center"}>
        <div className={`${classes.CellLinesLimitContainer}`} title={params.row.voice}>
          {params.row.voice}
        </div>
      </div>
    ),
  });
  if (userRole.isAdminOrInternal(role) || currentUser?.companyID) {
    productsColumns.push({
      field: "userEmail",
      headerName: "User Email",
      width: 250,
      sortable: false,
      renderCell: (params: GridCellParams<any>) => (
        <div className={`${classes.CellLinesLimitContainer}`}>{params.row?.userEmail}</div>
      ),
    });
  }
  productsColumns.push({
    field: "createdAt",
    headerName: "Last updated",
    width: 250,
    sortingOrder: ["desc", "asc"],
    renderCell: (params: GridCellParams<any>) => (
      <div className={"d-flex align-items-center"}>
        {toDateAndTimeObject(params.row.createdAt).date} @ {toDateAndTimeObject(params.row.createdAt).time}
      </div>
    ),
  });
  productsColumns.push({
    field: "status",
    headerName: "Hide brand name",
    width: 200,
    sortable: false,
    renderCell: (params: GridCellParams<any>) => (
      <div className={"d-flex align-items-center"}>
        <div className={"d-flex w-100 gap-2 align-items-center"}>
          <Tooltip title={params.row?.status ? "Enable" : "Disable"} placement="top">
            <Switch size="small" checked={params.row?.status} onChange={() => handleHideToggle(params.row)} />
          </Tooltip>
        </div>
      </div>
    ),
  });
  productsColumns.push({
    field: "edit",
    headerName: "",
    width: 200,
    // sortingOrder: ["desc", "asc"],
    sortable: false,
    renderCell: (params: GridCellParams<any>) => (
      <div className={"d-flex align-items-center"}>
        <div className={"d-flex w-100 gap-2 align-items-center cursor-pointer"}>
          <Tooltip title={"Edit"} placement="top">
            <IconButton>
              <ModeEditOutlinedIcon color="primary" onClick={() => editBrand(params?.row)} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    ),
  });

  return (
    <div className={classes.Container}>
      {loading ? (
        <GridSkeleton componentType={"brandsAlias"} isCompanyUser={currentUser?.companyID} />
      ) : (
        <ProductTable
          total={totalBrands}
          pageSize={pageSize}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          setPageSize={setPageSize}
          columns={productsColumns}
          rows={brandsList ?? []}
          sortModel={[
            {
              field: sortBy || "name",
              sort: sortOrder === 1 ? ("asc" as any) : ("desc" as any),
            },
          ]}
          onCellClick={handleCellClick}
          setSortModel={setSortModel}
          onSelectionModelChange={onSelectedRows}
          selectionModel={selectionModel}
          checkboxSelection={false}
        />
      )}
    </div>
  );
};

export default BrandAliasTable;
