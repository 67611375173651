import { GenerateKeywordObject } from "../../../apis/types/product";

export const minKeywordsRequired = 5;
export const SearchVolumeTooltip = "An estimation of the number of monthly searches that take place for this keyword";
export const RelevancyTooltip =
  "An AI calculated number from 1-100 that estimates how relevant the following keywords are to your ASIN with 100 being most relevant and 0 be least relevant";
export const CountTooltip =
  "Top 10 rank Count” with tooltip=”the number of your selected competitors that rank in the top 10 organic position for the keyword.";

export const isCardActive = (arr: any, index: number): boolean => {
  return arr.includes(index);
};

export const generateSelectionModal = (selectionModel: any, currentIndex: number): any => {
  const isCurrentIndexExist = selectionModel.includes(currentIndex);
  if (isCurrentIndexExist) {
    return selectionModel.filter((item: any) => item !== currentIndex);
  } else {
    return [...selectionModel, currentIndex];
  }
};

export const createKeywordObject = (product: any): GenerateKeywordObject => {
  console.log("test pick hit", product);
  if (typeof product === "object") {
    if (Object.keys(product).length > 5) {
      return {
        ...product,
      };
    } else if (product.hasOwnProperty("sellingKeyword")) {
      return {
        phrase: product.phrase,
        searchVolume: product.searchVolume,
        sellingKeyword: product.sellingKeyword,
      };
    }
    if (product.hasOwnProperty("average")) {
      return {
        phrase: product.phrase,
        searchVolume: product.searchVolume,
        average: product?.average || 0,
      };
    } else {
      return {
        phrase: product.phrase,
        searchVolume: product.searchVolume,
      };
    }
  } else {
    return {
      phrase: product,
      searchVolume: 0,
      sellingKeyword: true,
    };
  }
};

export const getKeywordType = (id: string): string => {
  if (id.includes("suggestions")) {
    return "suggestions";
  } else if (id.includes("selling")) {
    return "selling";
  } else if (id.includes("selectedKeywords")) {
    return "selectedKeywords";
  } else if (id.includes("restKeywords")) {
    return "restKeywords";
  }

  return "";
};

export const doAndDontKeywordPage = [
  {
    do: true,
    title: "select Spanish keywords that relate to your product.",
  },
  {
    do: true,
    title: "select misspelled keywords that relate to your product.",
  },
  {
    do: true,
    title: "double check keywords that have high monthly search volume.",
  },
  {
    do: false,
    title: "select keywords with your competitor’s brand name in them.",
  },
  {
    do: false,
    title: "select keywords that make no sense.",
  },
];

export const doAndDontKWPPage = [
  {
    do: true,
    title:
      "Choose Keywords to put into your content with high competitiveness while maximizing total search volume exposure",
  },
  {
    do: false,
    title: "Select multiple similar keywords in the title to prevent repetitiveness",
  },
];
