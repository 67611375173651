import { ProductLiveStatusEnum } from "../../../../utils/constants/enums";

export const brandViewTabs = {
  list: "list",
  grid: "grid",
};

export const brandTabsValue = {
  brand: "Brand",
  asin: "ASIN",
};
export const brandTabs = [brandTabsValue.brand, brandTabsValue.asin];

export const brandsFilters = {
  oldest: { sortBy: "lastProductGeneratedAt", sortOrder: 1 },
  newest: { sortBy: "lastProductGeneratedAt", sortOrder: -1 },
  ascending: { sortBy: "productBrand", sortOrder: 1 },
  descending: { sortBy: "productBrand", sortOrder: -1 },
};

// same useing for sorting asins on mobile devices
export const brandSortOption = [
  { label: "Newest", value: brandsFilters.newest },
  { label: "Oldest", value: brandsFilters.oldest },
  { label: "Alphabetically A-Z", value: brandsFilters.ascending },
  { label: "Alphabetically Z-A", value: brandsFilters.descending },
];

export const contentFilters = {
  all: "All",
  live: ProductLiveStatusEnum.LIVE,
  inactive: ProductLiveStatusEnum.INACTIVE,
  partiallyLive: ProductLiveStatusEnum.PARTIALLY_LIVE,
};

export const contentFiltersOption = [
  { label: "All", value: contentFilters.all },
  { label: "Live", value: contentFilters.live },
  { label: "Partially Live", value: contentFilters.partiallyLive },
  { label: "Inactive", value: ProductLiveStatusEnum.INACTIVE },
];
