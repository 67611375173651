import { AppState } from "../../reducers";
import { BestSellersRankObject, CompleteProduct } from "../../../apis/types/generate-product";

export const CompleteProductSelector = (state: AppState): CompleteProduct => state.productDetails.completeProduct;
export const BSRChartDropdownSelector = (state: AppState) => {
  const completeProduct = state.productDetails.completeProduct;
  const productAsin = completeProduct ? completeProduct.productASIN : "";
  const userID = completeProduct ? completeProduct.userID : "";
  if (completeProduct && completeProduct.bestSellersRank && completeProduct.bestSellersRank.length > 0) {
    const result: any = [];
    let firstProduct: any = null;

    completeProduct.bestSellersRank.forEach((product: BestSellersRankObject) => {
      if (product.hasOwnProperty("newCategory") && firstProduct === null) {
        firstProduct = { productAsin, category: product.category, userID };
      } else {
        result.push({ productAsin, category: product.category, userID });
      }
    });

    if (firstProduct) {
      return [firstProduct, ...result];
    } else {
      return result.sort((a: any, b: any) => a.rank - b.rank);
    }
  } else {
    return [];
  }
};
