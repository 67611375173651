import { CompleteProduct } from "../../../apis/types/generate-product";
import { produce } from "immer";
import { ProductDetailsActionTypes, ProductsDetailsReducerActionType } from "./productDetails.actions";

export interface State {
  completeProduct: CompleteProduct;
}

const initialState: State = {
  completeProduct: {} as CompleteProduct,
};

const productDetailsReducer = produce((state: State = initialState, action: ProductsDetailsReducerActionType) => {
  switch (action.type) {
    case ProductDetailsActionTypes.setCompleteProduct:
      state.completeProduct = action.payload;
      return state;
    default:
      return state;
  }
});

export default productDetailsReducer;
