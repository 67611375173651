import { CompleteProduct } from "../../../../../apis/types/generate-product";
import { ContentStatusEnum } from "../../../../../utils/constants/enums";

export const longTextNumber = 500;
export const maxTitleLength = 250;
export const maxSearchTermLength = 250;
export const maxDescriptionLength = 2000;
export const allBulletPointsLength = 2500;
export const isLongCharacter = (str: string = "", textNumber: number = longTextNumber): boolean => {
  return str.length > textNumber;
};

export const handleTitleLiveStatus = (product: CompleteProduct) => {
  if (
    product &&
    product.status === ContentStatusEnum.APPROVED &&
    product?.productLive?.isTitleLive !== null &&
    product?.productLive?.isTitleLive !== undefined
  ) {
    return true;
  } else {
    return false;
  }
};

export const handleBulletPointsLiveStatus = (product: CompleteProduct) => {
  if (
    product &&
    product.status === ContentStatusEnum.APPROVED &&
    product?.productLive?.isBulletPointsLive !== null &&
    product?.productLive?.isBulletPointsLive !== undefined
  ) {
    return true;
  } else {
    return false;
  }
};
