import { Grid } from "@mui/material";
import React from "react";
import TeamStatCard from "../../TeamManagement/TeamStatsContainer/TeamStatCard/TeamStatCard";

interface ClientDetailStatsProps {
  isLoading: boolean;
  isCompany: boolean;
  userData: any;
  // stats: any;
}
const ClientDetailStats = (props: ClientDetailStatsProps) => {
  const { userData, isLoading, isCompany } = props;

  // const getLiveStat = (): number => {
  //   let live = 0;
  //   if (stats?.productCountsWithStatuses.length > 0) {
  //     stats?.productCountsWithStatuses.forEach((item: any) => {
  //       if (item?._id === "live") {
  //         live = item?.count;
  //       }
  //     });
  //   }
  //   return live;
  // };
  // const liveStat = getLiveStat();

  // console.warn("stats checking ***** ", userData);
  return (
    <div>
      <Grid
        container
        flexDirection={"row"}
        px={{ xs: 2, lg: 4 }}
        spacing={{ xs: 1.5, md: 2 }}
        textAlign={"center"}
      >
        {isCompany && (
          <Grid item xs={6} lg={3}>
            <TeamStatCard title="Total Member" volume={userData?.totalMembers || 0} isLoading={isLoading} />
          </Grid>
        )}
        <Grid item xs={6} lg={isCompany ? 3 : 4}>
          <TeamStatCard title="Total Products" volume={userData?.totalProducts || 0} isLoading={isLoading} />
        </Grid>
        <Grid item xs={6} lg={isCompany ? 3 : 4}>
          <TeamStatCard title="Total Approved" volume={userData?.totalApprovedProducts || 0} isLoading={isLoading} />
        </Grid>
        <Grid item xs={isCompany ? 6 : 12} lg={isCompany ? 3 : 4}>
          <TeamStatCard title="Total Live" volume={userData?.totalLivedProducts || 0} isLoading={isLoading} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ClientDetailStats;
