import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import classes from "./index.module.css";
import { formatNumberWithCommas } from "../../../../../utils/helpers/numbers";
import { useParams } from "react-router-dom";
import { ParamsEnum } from "../../../../../utils/constants/enums";
import ratingStarFullColor from "../../../../../assets/svgs/Ratings/ratingFullStar.svg";
import ratingEmptyStar from "../../../../../assets/svgs/Ratings/ratingEmptyStar.svg";
import ratingStarHalfFilled from "../../../../../assets/svgs/Ratings/ratingStarHalfFilled.svg";

interface ScatterChartProps {
  chartSeries: any;
  chartColors: string[];
}

const BsrScatterChart: React.FC<ScatterChartProps> = ({ chartSeries, chartColors }) => {
  const params = useParams();
  const options: ApexOptions = {
    chart: {
      id: "bsr_scatter",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: "end",
      formatter: function (_value, { seriesIndex, dataPointIndex, w }) {
        if (w?.config?.series[seriesIndex]?.name === (params[ParamsEnum.ASIN] as string)) {
          return w.config.series[seriesIndex]?.data[dataPointIndex]?.text;
        }
        return "";
      },
      style: {
        fontSize: "10px",
        fontFamily: "Poppins",
        fontWeight: 600,
        colors: ["#E6B540"],
      },
      background: {
        enabled: false,
      },
      offsetX: -7,
      offsetY: 0,
    },
    xaxis: {
      type: "numeric",
      title: {
        text: undefined,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      labels: {
        hideOverlappingLabels: true,
        showDuplicates: false,
        minHeight: 50,
        style: {
          colors: "#4F4F4F",
          fontSize: "10px",
          fontWeight: 600,
          fontFamily: "Poppins",
        },
        formatter: (value) => {
          return "#" + formatNumberWithCommas(Number((-1 * Number(value)).toFixed()));
        },
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
      forceNiceScale: false,
      labels: {
        minWidth: 0,
        align: "left",
        style: {
          colors: "#4F4F4F",
          fontSize: "10px",
          fontWeight: 600,
          fontFamily: "Poppins",
        },
        formatter: (val) => {
          return formatNumberWithCommas(val);
        },
      },
    },
    legend: {
      fontFamily: "Poppins",
      fontSize: "12px",
      fontWeight: 600,
      formatter: (seriesName, opts) => {
        if (
          seriesName === (params[ParamsEnum.ASIN] as string) &&
          opts?.w?.globals?.initialSeries[opts.seriesIndex]?.data[0]?.text
        ) {
          return (
            seriesName +
            '<span style="color:#E6B540 !important; font-size: 10px;"> (' +
            opts?.w?.globals?.initialSeries[opts.seriesIndex]?.data[0]?.text +
            ")</span>"
          );
        } else if (opts?.w?.globals?.initialSeries[opts.seriesIndex]?.data?.length === 0) {
          return '<span style="color: #bfbfbf">' + seriesName + "<span/>";
        } else {
          return seriesName;
        }
      },
      labels: {
        colors: "#000",
      },
    },
    grid: {
      show: true,
      borderColor: "#D8D8D8",
      strokeDashArray: 2,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 50,
      },
    },
    fill: {
      opacity: 1,
    },
    title: {
      text: "",
      align: "center",
    },
    colors: chartColors.map((color, index) => {
      return chartSeries[index]?.data?.length > 0 ? color : "#a6a6a6";
    }),
    markers: {
      size: [9, 9, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7],
    },
    tooltip: {
      followCursor: true,
      custom: function ({ seriesIndex, dataPointIndex, w }) {
        const seriesName = w.globals.initialSeries[seriesIndex].name;
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        let tooltipContent = `<div class=${classes.TooltipCard}>`;
        tooltipContent += "<div class='d-flex flex-row'>";
        tooltipContent += `<img src=${data.imageURL} alt='product' class=${classes.TooltipProductImage} />`;
        tooltipContent += '<div class="d-flex flex-column ms-2 w-100">';
        tooltipContent += '<div class="d-flex">';
        tooltipContent += `<span class=${classes.TooltipProductAsin}>${seriesName}</span>`;
        tooltipContent += "</div>";
        tooltipContent += '<div class="d-flex mt-1">';
        tooltipContent += `<span class=${classes.TooltipProductTitle}>${data.title}</span>`;
        tooltipContent += "</div>";
        tooltipContent += '<div class="d-flex align-items-center">';
        tooltipContent += `<span class=${classes.TooltipProductBy}>By: </span>`;
        tooltipContent += `<span class=${classes.TooltipProductBrand}>${data.brand}</span>`;
        tooltipContent += "</div>";
        tooltipContent += '<div class="d-flex align-items-center mt-1">';
        if (data.rating === 0) {
          tooltipContent += `<img src=${ratingEmptyStar} alt='star'  height="14" width="14"/>`;
          tooltipContent += `<img src=${ratingEmptyStar} alt='star'  height="14" width="14"/>`;
          tooltipContent += `<img src=${ratingEmptyStar} alt='star'  height="14" width="14"/>`;
          tooltipContent += `<img src=${ratingEmptyStar} alt='star'  height="14" width="14"/>`;
          tooltipContent += `<img src=${ratingEmptyStar} alt='star'  height="14" width="14"/>`;
        } else {
          for (let i = 1; i <= 5; i++) {
            if (data.rating >= i) {
              tooltipContent += `<img src=${ratingStarFullColor} alt='star' height="14" width="14"/>`;
            } else if (data.rating > i - 1 && data.rating < i) {
              tooltipContent += `<img src=${ratingStarHalfFilled} alt='star'  height="14" width="14"/>`;
            } else {
              tooltipContent += `<img src=${ratingEmptyStar} alt='star' height="14" width="14"/>`;
            }
          }
        }
        tooltipContent += `<span class=${classes.TooltipTotalRatings}>${formatNumberWithCommas(
          data.ratings_total
        )}</span>`;
        tooltipContent += `<span class=${classes.TooltipTotalRatings}>ratings</span>`;
        tooltipContent += "</div>";
        tooltipContent += "</div>";
        tooltipContent += "</div>";
        tooltipContent += `<div class=${classes.TooltipRecordsDiv} style="background-color: ${chartColors[seriesIndex]}">`;
        tooltipContent += "<div class='d-flex'>";
        tooltipContent += `<span class=${classes.TooltipCardLeftText}>BSR:</span>`;
        tooltipContent += `<span class=${classes.TooltipCardRightText}>#${formatNumberWithCommas(-1 * data.x)}</span>`;
        tooltipContent += "</div>";
        tooltipContent += "<div class='d-flex mt-2'>";
        tooltipContent += `<span class=${classes.TooltipCardLeftText}>Search Volume:</span>`;
        tooltipContent += `<span class=${classes.TooltipCardRightText}>${formatNumberWithCommas(data.y)}</span>`;
        tooltipContent += "</div>";
        tooltipContent += "</div>";
        tooltipContent += "</div>";

        return tooltipContent;
      },
    },
  };

  return <ReactApexChart options={options} series={chartSeries} type="scatter" height={400} />;
};

export default BsrScatterChart;
