import React from "react";
import MTypography from "../../Atoms/MTypography";
import classes from "./index.module.css";

interface MenuOptionProps {
  children: React.ReactNode;
  borderBottom?: boolean;
}

const MenuOption = ({ children, borderBottom = false }: MenuOptionProps) => {
  return (
    <div className={`p-2 w-100  ${borderBottom ? classes.borderBottom : ""}`}>
      <MTypography variant={"subtitle2"} customClass={classes.CaptionColor}>
        {children}
      </MTypography>
    </div>
  );
};

export default MenuOption;
