import React from "react";
import classes from "./index.module.css";
import MTypography from "../MTypography";
import { useDeviceType } from "../../../hooks/useDeviceType";

interface KpiBoxesProps {
  topText?: string;
  middleText?: string;
  bottomText?: string;
  topTextBackgroundColor?: string;
  topTextColor?: string;
}

const InfoKpiBoxes: React.FC<KpiBoxesProps> = ({
  topText,
  middleText,
  bottomText,
  topTextColor = "#0CC683",
  topTextBackgroundColor = "#BFFFE8",
}) => {
  const { isDesktop } = useDeviceType();
  return (
    <div className={isDesktop ? classes.KpiCard : classes.KpiCardM}>
      {topText && (
        <div className={classes.KpiCardTopDiv} style={{ backgroundColor: topTextBackgroundColor }}>
          <MTypography
            variant={"subtitle1"}
            customClass={`text-center ${isDesktop ? classes.KpiCardTopBottomText : classes.KpiCardTopBottomTextM}`}
            color={topTextColor}
          >
            {topText}
          </MTypography>
        </div>
      )}
      {middleText && (
        <div className={`d-flex ${isDesktop ? "" : "mt-3"} justify-content-center align-items-center`} style={{ marginTop: '1.4rem' }}>
          <MTypography
            title={middleText}
            variant={"h1"}
            customClass={isDesktop ? classes.KpiCardMiddleText : classes.KpiCardMiddleTextM}
          >
            {middleText}
          </MTypography>
        </div>
      )}
      {bottomText && (
        <div>
          <MTypography
            variant={"subtitle1"}
            customClass={isDesktop ? classes.KpiCardTopBottomText : classes.KpiCardTopBottomTextM}
            color={topTextColor}
          >
            {bottomText}
          </MTypography>
        </div>
      )}
    </div>
  );
};

export default InfoKpiBoxes;
