import { Grid } from "@mui/material";
import React from "react";
import TeamStatCard from "../../TeamManagement/TeamStatsContainer/TeamStatCard/TeamStatCard";
import { ClientsActiveTab, ClientsStatsSelector } from "../../../../store/clients/clients.selector";
import { useSelector } from "react-redux";
import { clientsTabsValue } from "../../../../pages/Clients/config";
import CardWithSideChart from "../../../Molecules/CardWithSideChart/CardWithSideChart";

interface ClientsCardsContainerProps {
  loading: boolean;
}

const ClientsCardsContainer = ({loading} : ClientsCardsContainerProps) => {
  const activeTab = useSelector(ClientsActiveTab);
  const clientsStats = useSelector(ClientsStatsSelector)

  return (
    <Grid
      container
      flexDirection={"row"}
      px={{ xs: 2, lg: 0 }}
      mt={{ xs: 2, lg: 0 }}
      spacing={{ xs: 1.5, md: 2 }}
      textAlign={"center"}
    >
      <Grid item xs={6} lg={3}>
        <TeamStatCard 
          title={activeTab === clientsTabsValue.companies ? "Total Companies" : "Total Individuals"}
          volume={clientsStats.totalClients || 0}
          isLoading={loading}
        />
      </Grid>
      <Grid item xs={6} lg={3}>
        <TeamStatCard title="Total Products" volume={clientsStats.totalProducts || 0} isLoading={loading} />
      </Grid>
      <Grid item xs={6} lg={3}>
        <TeamStatCard title="Total Approved" volume={clientsStats.totalApprovedProducts || 0} isLoading={loading} />
      </Grid>
      <Grid item xs={6} lg={3}>
        <CardWithSideChart title="Total Live" volume={clientsStats.totalLivedProducts || 0} isLoading={loading} showChart={false} />
      </Grid>
    </Grid>
  );
};

export default ClientsCardsContainer;
