import { DatabaseUser } from "./user"

export interface GetAllClientsPayload {
  offset: number,
  limit: number,
  sortBy: string,
  sortOrder: number,
  searchQuery: string,
  isIndividual: boolean
}

export const getAllClientsDefaultPayload: GetAllClientsPayload = {
  offset: 0,
  limit: 0,
  sortBy: "",
  sortOrder: 0,
  searchQuery: "",
  isIndividual: false
}

export interface SetClientsStatsInterface {
  totalClients: number,
  totalRecords: number,
  totalApprovedProducts: number,
  totalLivedProducts: number,
  totalProducts: number
}

export interface ClientInterface {
  status: string,
  keywordTracking: boolean,
  _id: string,
  name: string,
  role: string,
  invitedEmails: string[],
  countHelium10Calls: number,
  countContentGeneration: number,
  countContentRegeneration: number,
  helium10CallsLimit: number,
  contentGenerationLimit: number,
  contentRegenerationLimit: number,
  createdAt: string,
  updatedAt: string
  __v: 0,
  totalMembers: 1
}


export interface ClientsDataInterface extends SetClientsStatsInterface {
  clients: ClientInterface[];
}

export interface GetAllClientsResponse {
  data: {
    statusCode: number;
    data: ClientsDataInterface;
  }
}

export interface IndividualClientInterface extends DatabaseUser {
  totalApprovedProducts: number,
  totalLivedProducts: number,
  totalProducts: number
}

export interface IndividualClientDetailsInterface {
  data: {
    statusCode: number;
    data: IndividualClientInterface;
  };
}