import { errorHandler } from "../../utils/helpers/apis";
import { generateProductApis } from "../../apis/generate-product";
// import { toDateOnly } from "../../utils/helpers/date";
// import {
//   manageKeywordCoverageRatio,
//   manageSearchIncrease,
// } from "../../components/Organisms/OpportunityReport/OpportunityGrid/config";
// import { removeComma } from "../../utils/helpers/strings";
import { UserApis } from "../../apis/user";
import { userRole } from "../../utils/helpers/priviligesChecks";
// import { exportCsvType } from "../../components/mui/dialogebox/exportAsinsDialoge";
// import * as XLSX from "xlsx";
// import { saveAs } from "file-saver";
import { CompanyApis } from "../../apis/companies";
// import { ContentStatusEnum } from "../../utils/constants/enums";

// const getExcelSheetData = (products: any): any => {
//   try {
//     const result: any = [];
//     products.forEach((item: any) => {
//       const sheet = [["", "AFTER", "BEFORE"]];
//       sheet.push(["Title", item?.title || "", item?.oldTitle || ""]);
//       for (let i = 0; i < 5; i++) {
//         sheet.push([
//           `BP ${i + 1}`,
//           item?.bulletPointsByChat[i],
//           item.hasOwnProperty("oldBulletPoints") ? item?.oldBulletPoints[i] : "",
//         ]);
//       }
//       sheet.push(["Description", item?.description, item?.oldDescription]);
//       sheet.push(["KEYWORDS", item?.selectedHelium10Keywords.join("\n"), ""]);
//       sheet.push(["COMPETITOR ASINs", item?.generatedContentCompetitors.join(", "), ""]);
//       sheet.push(["Title Keywords", item?.titleKeywords.join(", "), ""]);
//       sheet.push(["Rest of Keywords", item?.otherKeywords.join(", "), ""]);
//       result.push(sheet);
//     });

//     return result;
//   } catch (e) {
//     console.log("error", e);
//     return [];
//   }
// };

export const downloadSavedProductCsv = async ({
  showProtectedFields,
  brand,
  // exportType,
  teamUserID,
  isCompany,
  companyID,
}: any) => {
  try {
    // let dataArray: any[] = [];
    return await generateProductApis.getCsvProducts({ brand, userID: teamUserID, isCompany, companyID, showProtectedFields });
    // if (exportType === exportCsvType.row) {
    //   dataArray = products.map((product) => {
    //     const protectedFields = showProtectedFields
    //       ? {
    //           "User Email": product.userEmail ? product.userEmail : "N/A",
    //           "User Name": product.userName ? product.userName : "N/A",
    //         }
    //       : {};
          
    //       // getting editted title
    //       const titleToShow = product?.status !== ContentStatusEnum.APPROVED && product?.editedGeneratedProductContent?.title
    //       ? product?.editedGeneratedProductContent.title
    //       : product?.title || "";
    //     return {
    //       "Product ID (ASIN)": product.productASIN ? product.productASIN : "N/A",
    //       ...protectedFields,
    //       Brand: product.productBrand ? removeComma(product.productBrand) : "N/A",
    //       "Generated Title": titleToShow ? `"${titleToShow.replace(/"/g, '""')}"` : "N/A",
    //       "Generated Bullets":
    //         product.bulletPointsByChat && product.bulletPointsByChat.length
    //           ? `"${product.bulletPointsByChat
    //               .map((bullet) => bullet || "")
    //               .toString()
    //               .replace(/"/g, '""')}"`
    //           : "N/A",
    //       "Generated Description": product.description ? `"${product.description.replace(/"/g, '""')}"` : "N/A",
    //       "Keyword List":
    //         product.selectedHelium10Keywords && product.selectedHelium10Keywords.length
    //           ? `"${product.selectedHelium10Keywords
    //               // .map((obj) => obj.phrase || "")
    //               .toString()
    //               .replace(/"/g, '""')}"`
    //           : "N/A",
    //       "Keyword List Monthly After Search Term Volume": product?.newProductContentScore?.cumulativeSearchVolume
    //         ? `"${product.newProductContentScore.cumulativeSearchVolume}"`
    //         : 0,
    //       "Keyword List Monthly Before Search Term Volume": product?.existingProductContentScore?.cumulativeSearchVolume
    //         ? `"${product.existingProductContentScore.cumulativeSearchVolume}"`
    //         : 0,
    //       "Competitor List":
    //         product.generatedContentCompetitors && product.generatedContentCompetitors.length
    //           ? `"${product.generatedContentCompetitors
    //               // .map((obj) => obj.asin || "")
    //               .toString()
    //               .replace(/"/g, '""')}"`
    //           : "N/A",
    //       "Old Title": product.oldTitle ? `"${product.oldTitle.replace(/"/g, '""')}"` : "N/A",
    //       "Old Bullets":
    //         product.oldBulletPoints && product.oldBulletPoints.length
    //           ? `"${product.oldBulletPoints
    //               .map((bullet) => bullet || "")
    //               .toString()
    //               .replace(/"/g, '""')}"`
    //           : "N/A",
    //       "Old Description": product.oldDescription ? `"${product.oldDescription.replace(/"/g, '""')}"` : "N/A",
    //       "Generation Date": product.lastProductGeneratedAt ? toDateOnly(product.lastProductGeneratedAt) : "",
    //     };
    //   });
    //   const csvContent = [
    //     Object.keys(dataArray[0]).join(","), // Header row
    //     ...dataArray.map((item) => Object.values(item).join(",")), // Data rows
    //   ].join("\n");
    //   const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    //   const link = document.createElement("a");
    //   link.href = URL.createObjectURL(blob);
    //   link.download = "products.csv";
    //   link.style.display = "none";
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // } else {
    //   const workbook = XLSX.utils.book_new();

    //   const excelData = getExcelSheetData(products);

    //   if (excelData.length > 0) {
    //     excelData.forEach((product: any, index: number) => {
    //       const sheet = XLSX.utils.aoa_to_sheet(product);
    //       XLSX.utils.book_append_sheet(workbook, sheet, `Sheet${index}`);
    //     });
    //   }

    //   const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    //   const excelBlob = new Blob([excelBuffer], {
    //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //   });
    //   saveAs(excelBlob, "multi_sheet.xlsx");
    // }
  } catch (e) {
    throw new Error(errorHandler(e));
  }
};

// ****************** 
// ****************** 
// will remove it later after approving download csv link in email link
// ****************** 
// ****************** 

// export const downloadOpportunityReportCSV = async (allProducts: any) => {
//   try {
//     // return;
//     let dataArray: any[] = [];
//     const products = allProducts;
//     dataArray = products.map((product: any) => {
//       const searchInc = manageSearchIncrease(product.searchIncrease, product.volumeChange);
//       return {
//         "Product ID (ASIN)": product.productASIN ? product.productASIN : "N/A",
//         Brand: product.productBrand ? removeComma(product.productBrand) : "N/A",
//         "Generated Title": product.title ? `"${product.title.replace(/"/g, '""')}"` : "N/A",
//         "Search Increase": searchInc ? `"${searchInc === "∞" ? "Infinity" : searchInc}"` : 0,
//         "Volume Change": product.volumeChange ? `"${product.volumeChange}"` : 0,
//         "Keyword Coverage Ratio": product.keywordCoverageRatio
//           ? `"${manageKeywordCoverageRatio(product.keywordCoverageRatio)}"`
//           : 0,
//         "Last Live Date": product.lastLiveDate ? `"${product.lastLiveDate}"` : "N/A",
//       };
//     });
//     const csvContent = [
//       Object.keys(dataArray[0]).join(","), // Header row
//       ...dataArray.map((item) => Object.values(item).join(",")), // Data rows
//     ].join("\n");
//     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
//     const link = document.createElement("a");
//     link.href = URL.createObjectURL(blob);
//     link.download = "Opportunity.csv";
//     link.style.display = "none";
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   } catch (e) {
//     errorHandler(e);
//   }
// };

export const checkLiveProductLimit = 20;
// for regeneration limit check
export const checkIsProductOwnerReachedHisLimit = async (userID: string) => {
  return UserApis.getProductOwner(userID)
    .then((res: any) => {
      if (userRole.isAdminOrEditorOrInternalOrOwner(res?.role)) {
        return false;
      } else {
        return (
          res?.contentRegenerationLimit === res?.countContentRegeneration ||
          res?.helium10CallsLimit === res?.countHelium10Calls
        );
      }
    })
    .catch((error: any) => {
      console.log("error while fetching product owner", error);
    });
};

// for helium 10 limit check
export const checkIsUserReachedHisHelium10Limit = async (userID: string) => {
  return UserApis.getProductOwner(userID)
    .then((res: any) => {
      if (userRole.isAdminOrEditorOrInternalOrOwner(res?.role)) {
        return false;
      } else {
        return res?.helium10CallsLimit === res?.countHelium10Calls;
      }
    })
    .catch((error: any) => {
      console.log("error while fetching product owner", error);
    });
};

export const checkIsCompanyReachedHeliumOrContentRegenLimit = async (companyID: string) => {
  return CompanyApis.getCompany(companyID)
    .then((res: any) => {
      return (
        res?.contentRegenerationLimit === res?.countContentRegeneration ||
        res?.helium10CallsLimit === res?.countHelium10Calls
      );
    })
    .catch((error: any) => {
      console.log("error while fetching product owner", error);
    });
};

export const checkIsCompanyReachedHelium10Limit = async (companyID: string) => {
  return CompanyApis.getCompany(companyID)
    .then((res: any) => {
      return res?.helium10CallsLimit === res?.countHelium10Calls;
    })
    .catch((error: any) => {
      console.log("error while fetching product owner", error);
    });
};
