import { capitalizeEachLetter } from "../../utils/helpers/strings";
import { RolesEnum, SettingsEnum } from "../../utils/constants/enums";
import brandIcon from "../../assets/svgs/settingsIcons/settings-icon-brand.svg";
import profileIcon from "../../assets/svgs/settingsIcons/profile-icon.svg";
import gogoIcon from "../../assets/svgs/settingsIcons/settings-icon-gogo.svg";
import accessKeyIcon from "../../assets/svgs/settingsIcons/settings-icon-accessKey.svg";
import autoMatoAccessKeyIcon from "../../assets/svgs/accessKeysIcons/automato-accesskey-icon.svg";
import teamManagementIcon from "../../assets/svgs/settingsIcons/team-management-icon.svg";
import usersIcon from "../../assets/svgs/settingsIcons/users-icon.svg";

export const settingsMenu = [
  {
    title: capitalizeEachLetter(SettingsEnum.BRAND.replace("-", " ")),
    description: "Customize how your content appears to your customers",
    icon: brandIcon,
    name: SettingsEnum.BRAND,
  },
  {
    title: capitalizeEachLetter(SettingsEnum.COMPANY_PROFILE.replace("-", " ")),
    description: "Manage your company and access control ",
    icon: brandIcon,
    name: SettingsEnum.COMPANY_PROFILE,
  },
  {
    title: capitalizeEachLetter(SettingsEnum.USER_PROFILE.replace("-", " ")),
    description: "Change password, access and pricing options",
    icon: profileIcon,
    name: SettingsEnum.USER_PROFILE,
  },
  {
    title: capitalizeEachLetter(SettingsEnum.GoGoAutomato.split("-").join(" ")),
    description: "Fix numbers, threshold and weights for your products",
    icon: gogoIcon,
    name: SettingsEnum.GoGoAutomato,
  },
  {
    // title: capitalizeEachLetter(SettingsEnum.API_ACCESS_KEYS.split("-").join(" ")),
    title: "API Access Keys",
    description: "Manage and generates API access keys",
    icon: accessKeyIcon,
    name: SettingsEnum.API_ACCESS_KEYS,
  },
  {
    // title: capitalizeEachLetter(SettingsEnum.AUTOMATO_API_ACCESS.split("-").join(" ")),
    title: "AutoMato API Access",
    description: "Manage and generates API access keys",
    icon: autoMatoAccessKeyIcon,
    name: SettingsEnum.AUTOMATO_API_ACCESS,
  },
  {
    title: capitalizeEachLetter(SettingsEnum.TEAM_MANAGEMENT.split("-").join(" ")),
    description: "Manage team members and their access control",
    icon: teamManagementIcon,
    name: SettingsEnum.TEAM_MANAGEMENT,
  },
  {
    title: capitalizeEachLetter(SettingsEnum.USERS.split("-").join(" ")),
    description: "Users with authorized access to manage and control data",
    icon: usersIcon,
    name: SettingsEnum.USERS,
  },
];

const allowedSettings: any = {
  [RolesEnum.ADMIN]: [SettingsEnum.BRAND, SettingsEnum.API_ACCESS_KEYS, SettingsEnum.USERS],

  [RolesEnum.INTERNAL]: [SettingsEnum.BRAND, SettingsEnum.AUTOMATO_API_ACCESS],
  [RolesEnum.USER]: [SettingsEnum.BRAND, SettingsEnum.AUTOMATO_API_ACCESS, SettingsEnum.USER_PROFILE],
  [RolesEnum.COMPANY_ADMIN]: [
    SettingsEnum.BRAND,
    SettingsEnum.COMPANY_PROFILE,
    SettingsEnum.AUTOMATO_API_ACCESS,
    SettingsEnum.TEAM_MANAGEMENT,
  ],
};

allowedSettings[RolesEnum.OWNER] = allowedSettings[RolesEnum.ADMIN];
allowedSettings[RolesEnum.EDITOR] = allowedSettings[RolesEnum.INTERNAL];
allowedSettings[RolesEnum.PAID] = allowedSettings[RolesEnum.INTERNAL];
allowedSettings[RolesEnum.PAID_EDITOR] = allowedSettings[RolesEnum.INTERNAL];

export function filterSettingsByRole(userRole: any) {
  const allowedSettingsForUserRole: any = allowedSettings[userRole];
  if (!allowedSettingsForUserRole) {
    return [];
  }

  return settingsMenu.filter((setting) => allowedSettingsForUserRole.includes(setting.name));
}
