import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDeviceType } from "../../../../hooks/useDeviceType";

import TabsToolbar from "../../../Molecules/TabsToolbar";
import MButton from "../../../Atoms/MButton";
import { URL_FETCH_ASIN, URL_PRODUCTS } from "../../../../routes/routes-path";
import AddIcon from "@mui/icons-material/Add";

import { navigatePage } from "../../../../utils/helpers/common";
import classes from "./index.module.css";
import MTypography from "../../../Atoms/MTypography";
import { TabsEnum } from "../../../../utils/constants/enums";
import ProductStatusCounter from "./ProductStatusCounter";

const SavedProductsHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isDesktop } = useDeviceType();

  const brand = location?.state?.productBrand;
  const handleBack = () => {
    navigate(URL_PRODUCTS);
  };

  return (
    <div className={classes.HeaderContainer}>
      {isDesktop && (
        <TabsToolbar title={brand || TabsEnum.PRODUCTS}>
          <div className={"d-flex gap-3 align-items-center"}>
            {brand && (
              <div onClick={handleBack} className={"cursor-pointer "}>
                <MTypography variant={"body2"} customClass={classes.BackButton}>
                  {"<Back"}
                </MTypography>
              </div>
            )}
            {/*<MButton size={"small"} variant="outlined" onClick={handleCsvExport}>*/}
            {/*  {!csvLoading ? "Export CSV/Excel file" : "Exporting..."}*/}
            {/*</MButton>*/}
            <MButton
              size={"small"}
              onClick={() => {
                navigatePage(URL_FETCH_ASIN, navigate);
              }}
            >
              <AddIcon /> {"Add New ASIN"}
            </MButton>
          </div>
        </TabsToolbar>
      )}
      {isDesktop && <ProductStatusCounter />}
    </div>
  );
};

export default SavedProductsHeader;
