import React from "react";
import MTypography from "../../../Atoms/MTypography";
import classes from "./index.module.css";
import ProductBrand from "../ProductBrand";
import MRating from "../../../Atoms/MRating";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import { useSelector } from "react-redux";
import MButton from "../../../Atoms/MButton";
import { calculateDiscountPercentage } from "../config";
import { ScrapeProductSelector } from "../../../../store/product/productScrape/productScrape.selectors";

interface BasicDetailsProps {
  openDetailBox: () => void;
  showDetailButton: boolean;
}

const BasicDetails: React.FC<BasicDetailsProps> = ({ openDetailBox, showDetailButton }) => {
  const { isDesktop } = useDeviceType();
  const scrapeProduct = useSelector(ScrapeProductSelector);
  return (
    <div className={"d-flex"}>
      <div>
        {scrapeProduct?.product?.main_image && (
          <div className={""}>
            <div
              className={
                showDetailButton
                  ? classes.DetailImageContainer
                  : isDesktop
                  ? classes.ImageContainer
                  : classes.ImageContainerM
              }
            >
              <img
                src={scrapeProduct?.product?.main_image?.link}
                alt="product"
                className={isDesktop ? `${showDetailButton ? classes.DetailsImage : classes.Image}` : classes.ImageM}
              />
              {scrapeProduct?.product?.buybox_winner?.rrp?.value && (
                <div className={classes.Coupon}>
                  <MTypography variant={"subtitle2"} customClass={classes.Discount}>
                    {`${calculateDiscountPercentage(
                      scrapeProduct?.product?.buybox_winner?.rrp?.value,
                      scrapeProduct?.product?.buybox_winner?.price?.value
                    )}% off`}
                  </MTypography>
                </div>
              )}
            </div>
            {/*TODO: Hide for the time being will be added in future*/}
            {/*{showDetailButton && (*/}
            {/*  <div className={"d-flex align-items-center mt-2"}>*/}
            {/*    {!!scrapeProduct?.product?.images?.length &&*/}
            {/*      scrapeProduct?.product?.images?.map((image, index) => (*/}
            {/*        <div key={index}>*/}
            {/*          {index < 4 ? (*/}
            {/*            <div*/}
            {/*              className={`${classes.OtherImagesContainer} ${*/}
            {/*                areImageIdsEqual(scrapeProduct?.product?.main_image?.link, image?.link) &&*/}
            {/*                classes.MainImageContainer*/}
            {/*              } ${index === 3 && classes.LastImageContainer}`}*/}
            {/*            >*/}
            {/*              <img src={image?.link} alt={"other-photos"} className={`${classes.OtherImages} `} />*/}
            {/*              {index === 3 && (*/}
            {/*                <MTypography variant={"body2"} customClass={classes.ImageCount}>*/}
            {/*                  {`${scrapeProduct?.product?.images_count - 3} +`}*/}
            {/*                </MTypography>*/}
            {/*              )}*/}
            {/*            </div>*/}
            {/*          ) : (*/}
            {/*            ""*/}
            {/*          )}*/}
            {/*        </div>*/}
            {/*      ))}*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>
        )}
      </div>

      <div className={isDesktop ? "w-100" : ""}>
        <div className={"mb-2"}>
          <MTypography variant={"h5"} customClass={isDesktop ? classes.Title : classes.TitleM}>
            {scrapeProduct?.product?.title}
          </MTypography>
        </div>

        {showDetailButton && (
          <div className={"my-1"}>
            <ProductBrand />
          </div>
        )}
        <div>
          <div className={"mt-1 d-flex align-items-center justify-content-between"}>
            <div className={"d-flex align-items-center"}>
              <MRating
                value={scrapeProduct?.product?.rating}
                isScrapeProduct={!!scrapeProduct.product}
                noGap={showDetailButton || !isDesktop}
              />
              {scrapeProduct?.product?.ratings_total && (
                <MTypography
                  variant={"body2"}
                  customClass={isDesktop ? classes.RatingText : classes.RatingTextM}
                >{`${scrapeProduct?.product?.ratings_total?.toLocaleString()} ratings`}</MTypography>
              )}
              {scrapeProduct?.product?.top_reviews?.length && (
                <MTypography
                  variant={"body2"}
                  customClass={`${isDesktop ? classes.ReviewsText : classes.ReviewsTextM} `}
                >{`${scrapeProduct?.product?.top_reviews?.length?.toLocaleString()} reviews`}</MTypography>
              )}
            </div>
          </div>
          <div className={"d-flex align-items-center justify-content-between mt-3"}>
            <div className={"d-flex align-items-center"}>
              {scrapeProduct?.product?.buybox_winner?.rrp?.raw ? (
                <div className={"d-flex align-items-center"}>
                  {scrapeProduct?.product?.buybox_winner?.price?.raw && (
                    <MTypography
                      variant={"body2"}
                      customClass={isDesktop ? classes.ActualPrice : classes.ActualPriceM}
                    >{`${scrapeProduct?.product?.buybox_winner?.rrp?.raw}`}</MTypography>
                  )}
                  {scrapeProduct?.product?.buybox_winner?.price?.raw && (
                    <MTypography
                      variant={"body2"}
                      customClass={isDesktop ? classes.Price : classes.PriceM}
                    >{`${scrapeProduct?.product?.buybox_winner?.price?.raw}`}</MTypography>
                  )}
                </div>
              ) : (
                <div>
                  {scrapeProduct?.product?.buybox_winner?.price?.raw && (
                    <MTypography
                      variant={"body2"}
                      customClass={classes.Price}
                    >{`${scrapeProduct?.product?.buybox_winner?.price?.raw}`}</MTypography>
                  )}
                </div>
              )}
            </div>
            <div className={"d-flex align-items-center"}>
              {!showDetailButton && (
                <MButton variant={"outlined"} onClick={openDetailBox} size={"small"}>
                  {"View Details"}
                </MButton>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDetails;
