import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";

import MainLoader from "./components/Atoms/MainLoader";
import AppRoutes from "./routes/routes";

import { useDialog } from "./components/Atoms/Dialog/useDialog";
import { setRoleDispatch, setUserDispatch } from "./store/user/user.actions";
import { establishUserLoginSession } from "./apis/auth";
import { errorHandler } from "./utils/helpers/apis";

import PricingContext from "./context/PricingContext";
import Layout from "./components/Layout";

import "./index.css";
import { setCompanyAction } from "./store/company/company.actions";

interface AppState {
  isLoading: boolean;
}

//This is an example of how to define the props in a component
interface Props {
  id?: string;
}

const App: React.FC<Props> = () => {
  const { isOpen, onOpen, onClose } = useDialog();
  const [state, setState] = useState<AppState>({
    isLoading: true,
  });

  const dispatch = useDispatch();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setState({
      ...state,
      isLoading: true,
    });

    establishUserLoginSession()
      .then((resp) => {
        if (resp?._id) {
          dispatch(setUserDispatch(resp));
          dispatch(setRoleDispatch(resp?.role));
          if (resp?.companyID) {
            dispatch(setCompanyAction(resp?.companyID));
          }
        }
        setState({ ...state, isLoading: false });
      })
      .catch((error: any) => {
        setState({ ...state, isLoading: false });
        toast.error(errorHandler(error));
      });
  }, []);

  if (state.isLoading) return <MainLoader />;
  else
    return (
      <PricingContext.Provider
        value={{
          isOpen,
          onClose,
          onOpen,
        }}
      >
        <Layout>
          <div className="App">
            <ToastContainer />
            <AppRoutes />
          </div>
        </Layout>
      </PricingContext.Provider>
    );
};

export default App;
