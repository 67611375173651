import * as yup from "yup";

export interface InviteUserObject {
  email: string;
  // role: string;
}

export const inputName = {
  email: "email",
  // role: "role",
};

export const initialValue: InviteUserObject = {
  email: "",
  // role: "",
};

export interface InviteUser {
  invites: InviteUserObject[];
}

export const initialFormValue = {
  invites: [initialValue],
};

export const validationSchema = yup.object().shape({
  email: yup.string().trim().required("Email is required"),
  // role: yup.string().trim().required("Role  is required"),
});
