import { CatalogEnum, TabsEnum } from "../../utils/constants/enums";
import { RolesEnum } from "../../utils/constants/enums";
import saveProductActiveMB from "../../assets/svgs/tabIcons/tab-icon-save-products-active-mb.svg";
import saveProductDisabled from "../../assets/svgs/tabIcons/tab-icon-saved-products-disabled.svg";
import saveProductDisabledD from "../../assets/svgs/tabIcons/tab-icon-saved-products-disabled-desktop.svg";
import saveProductD from "../../assets/svgs/tabIcons/tab-icon-saved-products-desktop.svg";
import blackListedWordsActiveMB from "../../assets/svgs/tabIcons/tab-icon-blacklisted-words-active-mb.svg";
import blacklistWordsDisabled from "../../assets/svgs/tabIcons/tab-icon-blacklist-words-disabled.svg";
import blacklistWordsDisabledD from "../../assets/svgs/tabIcons/tab-icon-blacklist-words-disabled-desktop.svg";
import blacklistWordsD from "../../assets/svgs/tabIcons/tab-icon-blacklisted-words.svg";
import settings from "../../assets/svgs/tabIcons/tab-icon-settings.svg";
import settingsD from "../../assets/svgs/tabIcons/tab-icon-settings-desktop.svg";
import settingsDisabled from "../../assets/svgs/tabIcons/tab-icon-settings-disabled.svg";
import settingsDisabledD from "../../assets/svgs/tabIcons/tab-icon-settings-disabled-desktop.svg";
import menuIcon from "../../assets/svgs/tabIcons/tab-icon-menu-mb.svg";
import menuIconActiveMB from "../../assets/svgs/tabIcons/tab-icon-menu-active-mb.svg";
import opportunityReportIconDisabled from "../../assets/svgs/tabIcons/tab-icon-opportunity-report-disabled.svg";
import opportunityReportIconD from "../../assets/svgs/tabIcons/tab-icon-opportunity-report-disabled-desktop.svg";
import clientsIconD from "../../assets/svgs/tabIcons/tab-icon-clients.svg";
import clientsIconDisabledD from "../../assets/svgs/tabIcons/tab-icon-clients-disabled.svg";
import clientsIconM from "../../assets/svgs/tabIcons/tab-icon-clients-M.svg";
import clientsIconDisabledM from "../../assets/svgs/tabIcons/tab-icon-clients-disabled-M.svg";

import opportunityReportIcon from "../../assets/svgs/tabIcons/tab-icon-opportunity-report.svg";
import opportunityReportIconMB from "../../assets/svgs/tabIcons/tab-icon-opportunity-report-active-mb.svg";
import { URL_CONGRATS, URL_PRODUCTS, URL_PRODUCT_CATALOG, URL_SIGN_IN, URL_SIGN_UP, URL_TEAM_MEMBERS, URL_UNSUBSCRIBE } from "../../routes/routes-path";
import {
  showUpgradeNowBannerOnLayout,
  showUpgradeNowBannerOnLayoutCompany,
} from "../../utils/helpers/priviligesChecks";
import { CompanyData } from "../../apis/types/companies";

export const isEnableNav = (currentUrl: string): boolean => {
  if (
    currentUrl.includes(TabsEnum.SETTINGS) ||
    currentUrl.includes(TabsEnum.BLACKLIST) ||
    currentUrl.includes(TabsEnum.SAVED) ||
    currentUrl.includes(TabsEnum.OPPORTUNITY_REPORT) ||
    currentUrl.includes(TabsEnum.MORE) ||
    currentUrl.includes(TabsEnum.CLIENTS) ||
    currentUrl.includes(`${URL_PRODUCT_CATALOG}/${CatalogEnum.DETAIL_PAGE}`)
  ) {
    return true;
  } else {
    return false;
  }
};
export const isEnableNavOnMobile = (currentUrl: string): boolean => {
  if (
    currentUrl.includes(TabsEnum.BLACKLIST) ||
    currentUrl.includes(TabsEnum.SAVED) ||
    currentUrl.includes(TabsEnum.CLIENTS) ||
    currentUrl.includes(TabsEnum.MORE) ||
    currentUrl.includes(TabsEnum.OPPORTUNITY_REPORT)
  ) {
    return true;
  } else {
    return false;
  }
};

export const isSignInOrSignUpMobile = (currentUrl: string): boolean => {
  if (currentUrl.includes(URL_SIGN_IN) || currentUrl.includes(URL_SIGN_UP)) {
    return true;
  } else {
    return false;
  }
};
export const ScreenTabs = {
  getScreenTabs: (isDesktop: boolean) => {
    const allTabs = [
      {
        name: TabsEnum.SAVED,
        title: "Products",
        logo: {
          active: isDesktop ? saveProductD : saveProductActiveMB,
          disabled: isDesktop ? saveProductDisabledD : saveProductDisabled,
        },
      },
      {
        name: TabsEnum.OPPORTUNITY_REPORT,
        title: "Opportunity Report",
        logo: {
          active: isDesktop ? opportunityReportIcon : opportunityReportIconMB,
          disabled: isDesktop ? opportunityReportIconD : opportunityReportIconDisabled,
        },
      },
      {
        name: TabsEnum.BLACKLIST,
        title: "Blocklist",
        logo: {
          active: isDesktop ? blacklistWordsD : blackListedWordsActiveMB,
          disabled: isDesktop ? blacklistWordsDisabledD : blacklistWordsDisabled,
        },
      },
      {
        name: TabsEnum.CLIENTS,
        title: "Clients",
        logo: {
          active: isDesktop ? clientsIconD : clientsIconM,
          disabled: isDesktop ? clientsIconDisabledD : clientsIconDisabledM,
        },
      },
      {
        name: TabsEnum.SETTINGS,
        title: "Settings",
        logo: {
          active: isDesktop ? settingsD : settings,
          disabled: isDesktop ? settingsDisabledD : settingsDisabled,
        },
      },
      {
        name: TabsEnum.MORE,
        title: "More",
        logo: {
          active: menuIconActiveMB,
          disabled: menuIcon,
        },
      },
    ];

    isDesktop ? allTabs.splice(5, 1) : allTabs.splice(4, 1);
    return allTabs;
  },
};

export const setTabOnReload = (params: string): string => {
  if (params.includes(TabsEnum.SAVED)) {
    return TabsEnum.SAVED;
  } else if (params.includes(TabsEnum.BLACKLIST)) {
    return TabsEnum.BLACKLIST;
  } else if (params.includes(TabsEnum.CLIENTS)) {
    return TabsEnum.CLIENTS;
  } else if (params.includes(TabsEnum.OPPORTUNITY_REPORT)) {
    return TabsEnum.OPPORTUNITY_REPORT;
  } else if (params.includes(TabsEnum.MORE)) {
    return TabsEnum.MORE;
  } else if (params.includes(TabsEnum.SETTINGS)) {
    return TabsEnum.SETTINGS;
  } else {
    return TabsEnum.SAVED;
  }
};

export const getLayoutHeightForUpgradeBanner = (
  isCompany: boolean,
  countContentGeneration: number,
  contentGenerationLimit: number,
  currentUserRole: RolesEnum
) => {
  const heightWithoutBanner = {
    appBarHeight: 80,
    appBarHeightM: 54,
  };

  const heightWithBanner = {
    appBarHeight: 140,
    appBarHeightM: 106,
  };

  const checkRole = isCompany
    ? showUpgradeNowBannerOnLayoutCompany(currentUserRole)
    : showUpgradeNowBannerOnLayout(currentUserRole);

  if (isCompany) {
    // for company users
    // if user is company admin and reached his limits then it will show
    if (checkRole && countContentGeneration === contentGenerationLimit) {
      return heightWithBanner;
    } else {
      return heightWithoutBanner;
    }
  } else {
    // not for company users
    if (checkRole && countContentGeneration === contentGenerationLimit) {
      return heightWithBanner;
    } else {
      return heightWithoutBanner;
    }
  }
};

export const showUpgradeBannerForCompany = (userRole: RolesEnum, currentCompany: CompanyData, bannerType: string) => {
  if (bannerType === "sidebar_banner") {
    if (currentCompany?.role === RolesEnum.TRIAL && userRole === RolesEnum.COMPANY_ADMIN) {
      return true;
    } else {
      return false;
    }
  } else if (bannerType === "top_appbarBanner") {
    if (
      currentCompany?.contentGenerationLimit === currentCompany?.countContentGeneration &&
      userRole === RolesEnum.COMPANY_ADMIN
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getTabTitle = (url: string) => {
  if (url === URL_UNSUBSCRIBE) {
    return "Unsubscribe"
  } else if (url === URL_CONGRATS) {
    return "Congratulations"
  } else if (url === URL_TEAM_MEMBERS) {
    return "Invite Team Members"
  } else if (url === URL_PRODUCTS) {
    return "Products"
  } else {
    return "Automato AI"
  }
}