import React from "react";
import { useNavigate } from "react-router-dom";

import BrandVoiceForm from "../../../../components/Organisms/BrandSettings/BrandVoice/BrandVoiceForm";
import MTypography from "../../../../components/Atoms/MTypography";
import TabsToolbar from "../../../../components/Molecules/TabsToolbar";
import { URL_LANDING_PAGE } from "../../../../routes/routes-path";
import { SettingsEnum, TabsEnum } from "../../../../utils/constants/enums";
import { navigatePage } from "../../../../utils/helpers/common";
import { useDeviceType } from "../../../../hooks/useDeviceType";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { capitalizeEachLetter } from "../../../../utils/helpers/strings";

const BrandAlaisForm: React.FC = () => {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceType();

  const navigateToSettingsTab = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.BRAND_ALIAS_MANAGEMENT}`, navigate);
  };

  return (
    <div>
      <div>
        {isDesktop ? (
          <TabsToolbar
            title={"AutoMato Incorporated"}
            children={
              <div className={"cursor-pointer"} onClick={navigateToSettingsTab}>
                <MTypography variant={"subtitle2"}>{"< Back"}</MTypography>
              </div>
            }
          />
        ) : (
          <div className={"d-flex flex-column p-2 m-2"}>
            <div className={"my-2 mx-2"}>
              <ArrowBackIcon onClick={navigateToSettingsTab} />
            </div>
            <div className={"my-2 mx-2"}>
              <MTypography variant={"h5"}>{capitalizeEachLetter(SettingsEnum.BRAND.replace("-", " "))}</MTypography>
            </div>
          </div>
        )}

        <div className={``}>
          <BrandVoiceForm isBrandAliasForm />
        </div>
      </div>
    </div>
  );
};

export default BrandAlaisForm;
