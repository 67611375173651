import React from "react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import MButton from "../../Atoms/MButton";
import ActionBox from "../ActionBox";
import { useDeviceType } from "../../../hooks/useDeviceType";

import { getUnique7digitsString } from "./config";
import classes from "./index.module.css";

const AuthorizeTopBar = () => {
  const { isMobile, isDesktop } = useDeviceType();
  const [openConfirmBox, setOpenConfirmBox] = useState<boolean>(false);

  const state = getUnique7digitsString(); // "2224477";
  // const APPLICATION_ID = 'amzn1.application-oa2-client.932b18a1c46f4f78910b79955ea7e229';
  const APPLICATION_ID = "amzn1.sp.solution.e29a84c4-04f3-40a9-bcc7-b188c87611f0";
  const SELLER_URL = "https://sellercentral.amazon.com";
  const OAUTH_URL = SELLER_URL + `/apps/authorize/consent?application_id=${APPLICATION_ID}&version=beta&state=${state}`;
  // const REDIRECT_URI = 'https://prod-backend.automatoai.com/login'

  const [searchParams] = useSearchParams();
  const [mode, setMode] = useState<boolean>(false); //new==false, renew=true

  // console.log("checking type of it *** ", typeof state);
  const startOauth = async () => {
    localStorage.removeItem("authState");
    localStorage.setItem("authState", state);
    if (!mode) {
      // console.log("startOauth", OAUTH_URL);
      window.open(OAUTH_URL, "_self");
    } else {
      const amazon_state = searchParams.get("amazon_state");
      const amazon_callback_uri = searchParams.get("amazon_callback_uri");
      const ReAuthURL = `${amazon_callback_uri}?amazon_state=${amazon_state}&version=beta`;
      window.open(ReAuthURL, "_self");
    }
  };

  useEffect(() => {
    for (let val of searchParams.entries()) {
      // console.log(val);
      if (val[0] === "amazon_state") setMode(true);
    }
  }, [searchParams]);

  const closeConfirmBox = () => setOpenConfirmBox(false);

  return (
    <>
      <div className={isDesktop ? classes.Container : classes.ContainerMobile}>
        <div
          className={`${isDesktop ? "d-flex align-items-center gap-2" : "d-flex align-items-center gap-2"} ${
            classes.TextContainer
          }`}
        >
          <InfoOutlinedIcon style={{ color: "#ffffff" }} fontSize={isMobile ? "small" : "medium"} />
          <div className={classes.Text}>
            {isMobile
              ? "Please authorize your Amazon account."
              : "To fully utilize our platform's features, please authorize your Amazon account."}
          </div>
        </div>
        <div
          className={
            isDesktop
              ? `${classes.Button} d-flex justify-content-end align-items-center`
              : `d-flex justify-content-end align-items-center`
          }
        >
          <MButton
            size={"small"}
            variant="contained"
            onClick={() => setOpenConfirmBox(true)}
            rootClass={isMobile ? classes.MButton : ""}
          >
            Authorize
          </MButton>
        </div>
        <ActionBox
          handleAction={startOauth}
          handleBack={closeConfirmBox}
          open={openConfirmBox}
          actionText={"Yes"}
          title={"Confirm Amazon Authorization"}
          message={"Would you like to authorize access to your Amazon account?"}
          backText={"No"}
        />
      </div>
    </>
  );
};

export default AuthorizeTopBar;
